<div
    class="wcs-w-full wcs-p-4 wcs-flex wcs-items-center wcs-flex-col sm:wcs-flex-row wcs-justify-center wcs-text-white wcs-text-lg wcs-font-semibold wcs-bg-accent wcs-rounded-t">
    <p class="sm:wcs-mr-5">{{ venue.name }}</p>
    <p class="sm:wcs-mr-5">{{ date | date:'EEEE, d MMMM yyyy' }}</p>
    <p class="sm:wcs-mr-5">{{ package.name }}</p>
    <p>{{ session.session_name }}</p>
</div>
<div class="wcs-w-full wcs-p-4 wcs-border wcs-border-accent wcs-border-t-0 wcs-rounded-b">
    <div class="wcs-w-full wcs-flex wcs-items-start wcs-flex-wrap">
        <div class="md:wcs-w-1/2 wcs-w-full wcs-mb-3 wcs-text-accent" *ngFor="let option of priceOptions; index as i"
            [class.md:wcs-pl-2]="i % 2 !== 0" [class.md:wcs-pr-2]="i % 2 === 0">
            <app-price-option
                [options]="priceOptions"
                [index]="i"
                [option]="option"
                [currency]="package.currency"
                [package]="package"
                [session]="session"
                [people]="people"
                (update)="calculateTotal($event)"
            ></app-price-option>
        </div>
    </div>
    <div class="wcs-w-full wcs-flex wcs-mb-2" *ngIf="options.length">
        <div class="md:wcs-w-1/2 wcs-w-full md:wcs-pr-1 wcs-mb-2 md:wcs-mb-0">
            <ng-select [items]="options" (change)="calculateTotal()" bindLabel="name" bindValue="val"
                [clearable]="false" [searchable]="false" [(ngModel)]="option" name="option"></ng-select>
        </div>
        <div class="md:wcs-w-1/2 wcs-w-full md:wcs-pl-1" *ngIf="option !== 'full'">
            <div class="wcs-w-full wcs-h-full wcs-flex wcs-items-center wcs-justify-center wcs-rounded wcs-bg-yellow-500">
                <p class="wcs-font-medium wcs-text-accent">
                    {{ dueBy }}
                </p>
            </div>
        </div>
    </div>
    <p class="wcs-mt-4 wcs-mb-1 wcs-text-sm wcs-text-gray-700 wcs-font-medium" *ngIf="paymentMethods > 1 && dueNow > 0">Select a payment method to secure your booking.</p>
    <div class="wcs-w-full wcs-flex wcs-flex-col md:wcs-flex-row wcs-items-start md:wcs-items-center wcs-mb-2">
        <div class="md:wcs-w-1/2 wcs-w-full wcs-rounded wcs-flex wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-overflow-hidden wcs-text-accent">
            <input type="text" name="promocode" [(ngModel)]="promocode" (keydown.enter)="applyPromoVoucher()" class="wcs-bg-transparent wcs-w-8/12 wcs-p-4 wcs-text-accent wcs-flex-1" placeholder="Promo/Voucher code" style="min-width: 100px;" />
            <button type="button" class="wcs-px-4 hover:wcs-bg-aaccent wcs-w-34/12" (click)="applyPromoVoucher()" style="background: rgba(74, 85, 104, 0.1);">
                <span *ngIf="!promoLoading">{{ 'payments.redeem' | translate }}</span>
                <div class="wcs-spinner-loader" *ngIf="promoLoading">
                    <div class="wcs-bounce1"></div>
                    <div class="wcs-bounce2"></div>
                    <div class="wcs-bounce3"></div>
                </div>
            </button>
        </div>
        <div class="wcs-flex-1 wcs-ml-4">
            <div *ngIf="promo" class="md:wcs-mt-0 wcs-mt-2 wcs-flex wcs-items-center wcs-justify-between">
                <div class="flex-1">
                    <div class="wcs-ml-4 wcs-p-1 wcs-font-medium wcs-rounded wcs-text-white wcs-bg-green-500 wcs-text-xs" *ngIf="promo.promo.promo_fixed">
                        -{{ promo.promo.promo_fixed | currency:booking.currency }}: {{ promo.promo.name }}
                    </div>
                    <div class="wcs-ml-4 wcs-p-1 wcs-font-medium wcs-rounded wcs-text-white wcs-bg-green-500 wcs-text-xs" *ngIf="promo.promo.promo_percent">
                        -{{ promo.promo.promo_percent }}: {{ promo.promo.name }}
                    </div>
                    <div class="wcs-ml-4 wcs-p-1 wcs-font-medium wcs-rounded wcs-text-white wcs-bg-green-500 wcs-text-xs" *ngIf="promo.promo.promo_pp">
                        {{ promo.promo.promo_pp | currency:booking.currency }}pp: {{ promo.promo.name }}
                    </div>
                </div>
                <button
                    (click)="removePromo()" type="button"
                    class="wcs-text-red-400 hover:wcs-text-red-600 wcs-font-semibold wcs-text-2xl wcs-leading-none wcs-ml-4">&times;</button>
            </div>
            <div *ngIf="voucher && voucher.name !== voucher.message" class="md:wcs-mt-0 wcs-mt-2 wcs-flex wcs-items-center wcs-justify-between">
                <p class="wcs-text-gray-700 wcs-text-xs" *ngIf="voucher.name !== voucher.message">{{ voucher.message }}</p>
            </div>
        </div>
    </div>
    <div class="wcs-mb-3 wcs-w-full" *ngIf="requestValidation">
        <div class="md:wcs-w-1/2 wcs-overflow-hidden">
            <input type="text" [formControl]="validation" class="wcs-bg-transparent wcs-rounded wcs-border wcs-w-full wcs-border-aaccent focus:wcs-border-accent wcs-w-8/12 wcs-p-4 wcs-text-accent wcs-text-accent" placeholder="Your Voucher Security Code*" style="min-width: 100px;" />
        </div>
    </div>
    <div class="wcs-mb-3 wcs-rounded wcs-p-3 wcs-flex wcs-items-start wcs-justify-end wcs-text-right wcs-text-lg wcs-text-accent wcs-w-full wcs-relative" style="background: rgba(74, 85, 104, 0.3)">
        <div class="wcs-mr-5">
            <p>{{ 'totals.cost' | translate }}:</p>
            <div class="wcs-relative wcs-flex wcs-items-center" *ngIf="voucher">
                <button
                    (click)="removeVoucher()"
                    type="button"
                    class="wcs-text-red-400 hover:wcs-text-red-600 wcs-font-semibold wcs-text-normal wcs-leading-none wcs-absolute wcs--ml-4 wcs-left-0"
                >&times;</button>
                <p>{{ voucher.name }}:</p>
            </div>
            <p *ngIf="fee > 0.00">{{ 'totals.fee' | translate }}:</p>
            <p class="wcs-font-semibold">{{ 'totals.due' | translate }}:</p>
        </div>
        <div *ngIf="!recalc">
            <p>{{ selectedOption?.price || total | currency:package.currency:'symbol-narrow' }}</p>
            <p *ngIf="voucher">-{{ voucher.balance_value | currency:package.currency:'symbol-narrow' }}</p>
            <p *ngIf="fee > 0.00">{{ fee | currency:package.currency:'symbol-narrow' }}</p>
            <p class="wcs-font-semibold">{{ dueNow | currency:package.currency:'symbol-narrow' }}</p>
        </div>
        <div class="wcs-absolute wcs-inset-0 wcs-bg-awhite wcs-flex wcs-items-center wcs-justify-center" *ngIf="recalc">
            <p class="wcs-text-accent wcs-font-medium wcs-text-xl">Recalculating...</p>
        </div>
    </div>
    <p class="wcs-text-gray-700 wcs-text-xs wcs-text-right" *ngIf="surcharge">A surcharge of {{ surcharge | currency:package.currency }} on the day</p>
    <p *ngIf="duration" class="wcs-text-gray-700 wcs-text-sm wcs-mb-2">{{ message }} {{ timer }}</p>
    <div class="wcs-items-center md:wcs-flex-row wcs-flex-col wcs-overflow-hidden md:wcs-h-55px" [ngClass]="dueNow > 0 ? 'wcs-flex' : 'wcs-hidden'">
        <div class="wcs-flex-1 md:wcs-pr-1 wcs-w-full md:wcs-w-auto wcs-h-full wcs-cursor-pointer wcs-mb-2 md:wcs-mb-0" (click)="cardHidden = !cardHidden" [class.wcs-hidden]="!processors.includes('stripe')">
            <div
                class="wcs-w-full wcs-h-full wcs-border wcs-border-accent wcs-rounded wcs-flex wcs-items-center wcs-justify-center wcs-py-2 md:wcs-py-0">
                <div class="wcs-h-8 wcs-w-8 wcs-mr-2 wcs-bg-contain wcs-bg-no-repeat"
                    style="background-image: url('https://geronigo.gitlab.io/bookit-light/assets/visa.svg')"></div>
                <div class="wcs-h-8 wcs-w-8 wcs-mr-2 wcs-bg-contain wcs-bg-no-repeat"
                    style="background-image: url('https://geronigo.gitlab.io/bookit-light/assets/master-card.svg')">
                </div>
                <div class="wcs-h-8 wcs-w-8 wcs-mr-2 wcs-bg-contain wcs-bg-no-repeat"
                    style="background-image: url('https://geronigo.gitlab.io/bookit-light/assets/american-express.svg')">
                </div> <span class="wcs-text-accent wcs-font-medium wcs-text-lg">{{ 'payments.card_payment' | translate }}</span>
            </div>
        </div>
        <div class="wcs-flex-1 md:wcs-pl-1 wcs-h-full md:wcs-w-auto wcs-w-full md:wcs-mb-0" [class.md:wcs-pr-1]="showPaymentRequest" [class.wcs-mb-2]="showPaymentRequest" [class.wcs-hidden]="!processors.includes('paypal')">
            <div class="wcs-w-full wcs-h-full" [class.wcs-pointer-events-none]="people <= 0" #paypalEl></div>
        </div>
        <div class="wcs-flex-1 md:wcs-pl-1 md:wcs-w-auto wcs-w-full wcs-h-full" [class.wcs-hidden]="!showPaymentRequest">
            <div class="wcs-w-full wcs-h-full" [class.wcs-pointer-events-none]="people <= 0" #nativeEl></div>
        </div>
    </div>
    <form class="wcs-mt-2 wcs-w-full" [formGroup]="form" (ngSubmit)="makeTransaction()">
        <div [class.wcs-hidden]="cardHidden || dueNow <= 0">
            <div class="wcs-w-full md:wcs-flex-row wcs-flex-col wcs-flex wcs-items-center wcs-mb-2">
                <div class="md:wcs-w-1/2 wcs-w-full md:wcs-pr-1 wcs-mb-2 md:wcs-mb-0"> <input formControlName="name"
                        class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full"
                        [placeholder]="'customer_data.cardholder_name' | translate" /> </div>
                <div class="md:wcs-w-1/2 wcs-w-full md:wcs-pl-1"> <input formControlName="email"
                        class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full"
                        [placeholder]="'customer_data.email' | translate" /> </div>
            </div>
            <div class="wcs-flex wcs-flex-wrap">
                <div class="wcs-rounded md:wcs-flex-1 md:wcs-w-auto wcs-w-full wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full">
                    <div class="wcs-w-full" #cardEl></div>
                </div>
                <input
                    formControlName="card_zip"
                    type="text"
                    class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-px-4 md:wcs-py-0 wcs-py-4 wcs-text-accent md:wcs-w-40 wcs-w-full md:wcs-ml-2 md:wcs-mt-0 wcs-mt-2"
                    placeholder="Post Code*"
                />
            </div>
        </div>
        <div class="wcs-w-full wcs-mt-4 wcs-flex md:wcs-flex-row wcs-flex-col-reverse wcs-items-center wcs-justify-between">
            <a href="javascript:void(0)" class="wcs-text-sm wcs-text-accent" (click)="back.emit()">
                <fa-icon [icon]="faChevronLeft"></fa-icon> {{ 'buttons.back' | translate }}
            </a>
            <button
                type="submit"
                class="wcs-py-2 wcs-px-4 wcs-text-accent wcs-rounded md:wcs-w-auto wcs-w-full md:wcs-mb-0 wcs-mb-2 wcs-border-accent wcs-border hover:wcs-bg-aaccent wcs-px-6"
                [disabled]="form.invalid || loading || people <= 0"
                *ngIf="!cardHidden || dueNow <= 0"
            >
                <span class="wcs-text" *ngIf="!loading">{{ 'buttons.submit' | translate }}</span>
                <span class="wcs-hint" *ngIf="!loading">{{ hint | translate }}</span>
                <div class="wcs-spinner-loader" *ngIf="loading">
                    <div class="wcs-bounce1"></div>
                    <div class="wcs-bounce2"></div>
                    <div class="wcs-bounce3"></div>
                </div>
            </button>
        </div>
    </form>
</div>
<div class="wcs-fixed wcs-flex wcs-inset-0 wcs-justify-center wcs-items-center" style="background: rgba(0, 0, 0, 0.5);" *ngIf="showModal">
    <div class="wcs-p-5 wcs-rounded wcs-bg-white" style="max-width: 500px">
        <p class="wcs-text-gray-700">Do you wish to continue with this booking?</p>
        <div class="wcs-w-full wcs-flex wcs-items-center wcs-justify-end wcs-mt-4">
            <!-- <div class="wcs-w-full wcs-flex wcs-items-center wcs-justify-end">
                <a href="javascript:void(0)" class="" (click)="selectItem.emit(selectedSession)">Yes</a>
                <a href="javascript:void(0)" class="" (click)="modal = 0">No</a>
            </div> -->
            <button
                type="button"
                [disabled]="availLoading"
                (click)="resetTimer()"
                class="wcs-p-2 wcs-text-green-600 wcs-mr-2 wcs-font-medium"
            >
                <span *ngIf="!availLoading" class="wcs-font-normal">Yes</span>
                <div class="wcs-spinner-loader" *ngIf="availLoading">
                    <div class="wcs-bounce1"></div>
                    <div class="wcs-bounce2"></div>
                    <div class="wcs-bounce3"></div>
                </div>
            </button>
            <button
                type="button"
                [disabled]="availLoading"
                (click)="toStart()"
                class="wcs-p-2 wcs-text-red-600 wcs-font-medium"
            >
                <span class="wcs-font-normal">No</span>
            </button>
        </div>
    </div>
</div>
