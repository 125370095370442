import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-price-option',
    templateUrl: './price-option.component.html'
})
export class PriceOptionComponent {
    @Input() options: any[];
    @Input() option: any;
    @Input() package: any;
    @Input() index: number;
    @Input() session: any;
    @Input() people: number;
    @Input() minPax: number;
    @Input() error = false;
    @Input() currency = 'GBP';

    @Output() update: EventEmitter<any[]> = new EventEmitter();

    public faMinus = faMinus;
    public faPlus = faPlus;

    public changeOption(option: any, amount: number): void {
        let maxPax = option.max_pax;

        const ignorePax = +this.session.bookings > 0 && +this.package.first_booking_pax;
        const minPax = this.minPax || +option.min_pax;
        const newQty = this.people + amount;

        if (this.session.max_pax && (!maxPax || maxPax > +this.session.max_pax)) maxPax = +this.session.max_pax;

        if (option.qty_requested + amount < 0) return;
        else if (minPax && newQty < minPax && amount > 0 && !ignorePax) amount = minPax;
        else if (minPax && this.people === minPax && amount < 0 && !ignorePax) amount = -minPax;
        else if (maxPax && option.qty_requested === maxPax && amount > 0) return;

        if (+this.package.max_pax && +this.package.max_pax < newQty) return;

        option.qty_requested += amount;

        this.setAltsPrices(this.people + amount);
    }

    private setAltsPrices(qty: number): void {
        const data = this.options.map(option => {
            if (!option.alts || !option.alts.length) return option;

            const price = option.alts.reduce((a, opt) => {
                const val = qty || 1;
                return a = +opt.min_pax <= val ? +(opt.price_calc || opt.price) : a;
            }, 0);

            if (price <= 0 && option.price_calc) option.price_calc = option._price_calc || option._price;
            else if (price <= 0) option.price = option._price_calc || option._price;
            else if (option.price_calc) option.price_calc = price;
            else option.price = price;

            return option;
        });

        this.update.emit(data);
        // if (!option.alts || !option.alts.length) return this.update.emit(option);

        // console.log(qty);

        // const price = option.alts.reduce((a, opt) => {
        //     const val = qty || 1;
        //     return a = +opt.min_pax <= val ? +(opt.price_calc || opt.price) : a;
        // }, 0);

        // if (price <= 0 && option.price_calc) option.price_calc = option._price_calc || option._price;
        // else if (price <= 0) option.price = option._price_calc || option._price;
        // else if (option.price_calc) option.price_calc = price;
        // else option.price = price;

        // this.update.emit(option);
    }
}
