import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { HttpService } from 'src/app/services/http.service';
import { getParameterByName } from 'src/app/helpers/price-options.helpers';

@Component({
    templateUrl: './venue-picker.component.html'
})
export class VenuePickerComponent implements OnInit {
    @Output() venueChange: EventEmitter<string> = new EventEmitter();

    @Input() venueId: string;

    public venues: any[] = [];

    constructor(
        private http: HttpService
    ) { }

    ngOnInit() {
        this.getVenues();
    }

    private getVenues(): void {
        const params: any = { directories: 'venues' };
        const companyId = getParameterByName('company_id');

        if (companyId) params.company_id = companyId;

        this.http.get('/directories', { params }).subscribe(res => {
            this.venues = res.body.venues;

            const venue = this.venues.find(item => item.venue_id === '1008000');

            if (!venue) {
                this.venueId = this.venues[0].venue_id;
                this.venueChange.emit(this.venueId);
            } else {
                this.venueId = venue.venue_id;
                this.venueChange.emit(this.venueId);
            }
        });
    }
}
