<ng-template #headerTemplate let-days="days" let-localet="locale">
    <div class="wcs-w-full wcs-flex wcs-items-center wcs-font-semibold wcs-text-md wcs-bg-gray-100 wcs-text-xs wcs-border-gray-700 wcs-text-gray-800">
        <div class="wcs-flex-grow wcs-py-2">
            <p class="wcs-m-0 wcs-text-center">{{ 'week_days.mon' | translate }}</p>
        </div>
        <div class="wcs-flex-grow wcs-py-2">
            <p class="wcs-m-0 wcs-text-center">{{ 'week_days.tue' | translate }}</p>
        </div>
        <div class="wcs-flex-grow wcs-py-2">
            <p class="wcs-m-0 wcs-text-center">{{ 'week_days.wed' | translate }}</p>
        </div>
        <div class="wcs-flex-grow wcs-py-2">
            <p class="wcs-m-0 wcs-text-center">{{ 'week_days.thu' | translate }}</p>
        </div>
        <div class="wcs-flex-grow wcs-py-2">
            <p class="wcs-m-0 wcs-text-center">{{ 'week_days.fri' | translate }}</p>
        </div>
        <div class="wcs-flex-grow wcs-py-2">
            <p class="wcs-m-0 wcs-text-center">{{ 'week_days.sat' | translate }}</p>
        </div>
        <div class="wcs-flex-grow wcs-py-2">
            <p class="wcs-m-0 wcs-text-center">{{ 'week_days.sun' | translate }}</p>
        </div>
    </div>
</ng-template>
<ng-template  #cellTemplate let-day="day" let-locale="locale">
    <div class="wcs-flex wcs-justify-center wcs-items-center wcs-py-2 wcs-text-sm wcs-cursor-pointer">
        <div class="wcs-rounded-full wcs-flex wcs-justify-center wcs-items-center wcs-border-solid wcs-border-2 wcs-w-8 wcs-h-8 wcs-month-day wcs-border-transparent">
            <span [class.wcs-opacity-50]="day.isPast" [class.wcs-text-accent]="day.isPast" [class.wcs-text-white]="!day.isPast" class="wcs-font-bold">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        </div>
    </div>
</ng-template>
<div class="wcs-w-full wcs-rounded-lg mat-shadow wcs-relative wcs-bg-white">
    <div class="wcs-w-full wcs-text-sm wcs-px-2 wcs-py-2 wcs-flex wcs-items-center wcs-justify-between">
        <a href="javascript:void(0)" class="wcs-font-normal wcs-text-accent" (click)="changeViewDate('prev')">
            <fa-icon [icon]="faChevronLeft"></fa-icon> {{ 'buttons.prev' | translate}}
        </a>
        <p class="wcs-m-0 wcs-text-lg wcs-text-accent">{{ viewDate | date:'MMMM yyyy' }}</p>
        <a href="javascript:void(0)" class="wcs-font-normal wcs-text-accent" (click)="changeViewDate('next')">
            {{ 'buttons.next' | translate }} <fa-icon [icon]="faChevronRight"></fa-icon>
        </a>
    </div>
    <div class="wcs-relative">
        <mwl-calendar-month-view
            [viewDate]="viewDate"
            [headerTemplate]="headerTemplate"
            [cellTemplate]="cellTemplate"
            [refresh]="refresh"
            (beforeViewRender)="beforeMonthViewRender($event)"
            (dayClicked)="dayClicked($event)"
        ></mwl-calendar-month-view>
        <div class="wcs-absolute wcs-inset-0 wcs-flex wcs-items-center wcs-justify-center" style="background: rgba(255, 255, 255, 0.5);" *ngIf="loading">
            <div class="wcs-w-32 wcs-h-32 wcs-rounded-full wcs-border-t-2 wcs-border-r-2 wcs-border-solid wcs-spin wcs-border-main"></div>
        </div>
    </div>
</div>