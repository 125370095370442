import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';

import { Package, Availability } from 'src/app/models/venues.model';
import { PaymentService } from 'src/app/services/payment.service';
import { applyPromo } from 'src/app/helpers/price-options.helpers';
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-customer-data',
    templateUrl: './customer-data.component.html'
})
export class CustomerDataComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() booking: any;
    @Input() venue: any;
    @Input() package: Package;
    @Input() session: any;
    @Input() date: Date;
    @Input() formData: any;
    @Input() priceOptions: any[] = [];
    @Input() terms: any[] = [];
    @Input() dirs: any;
    @Input() loading: string;
    @Input() promo: any;

    @Output() back: EventEmitter<any> = new EventEmitter();
    @Output() apply: EventEmitter<{ type: string, item: any }> = new EventEmitter();
    @Output() createBooking: EventEmitter<{ form: any, priceOptions?: any, enquiry?: boolean}> = new EventEmitter();

    public faChevronLeft = faChevronLeft;

    public form: UntypedFormGroup;
    public error = false;
    public total = 0;
    public fee = 0;
    public people = 0;
    public showMessage = false;
    public minPax: number;

    private timerSub: Subscription;

    constructor(
        private builder: UntypedFormBuilder,
        private payments: PaymentService,
        private http: HttpService
    ) { }

    ngOnInit() {
        this.form = this.builder.group({
            first_name: [this.formData ? this.formData.first_name : '', Validators.required],
            last_name: [this.formData ? this.formData.last_name : ''],
            customer_email: [this.booking ? this.booking.customer_email : '', [Validators.required, CustomValidators.email]],
            customer_tel: [this.booking ? this.booking.customer_tel : '', Validators.required],
            title: [this.booking ? this.booking.title : ''],
            group_type: [this.booking ? (this.booking.group_type || null) : null],
            message: [this.booking ? this.booking.message : ''],
            terms: [false, Validators.requiredTrue],
            subscribed: [false]
        });

        const month = moment(this.date).format('M');
        const day = +moment(this.date).format('D');

        const holiday = (this.venue.holidays || []).find(item => (
            item.day === day &&
            item.month === month &&
            (!item.package_ids.length || item.package_ids.includes(this.package.package_id))
        ));

        if (holiday && holiday.min_pax) this.minPax = holiday.min_pax;

        this.form.setValue({ ...this.form.getRawValue(), ...this.formData });

        this.applyPromoVoucher();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            document.querySelector('#rezbot-session-bookit').scrollIntoView();
        });
    }

    ngOnDestroy() {
        if (this.timerSub) this.timerSub.unsubscribe();
    }

    public get acceptPayments(): boolean {
        return this.payments.initialized;
    }

    public goBack(): void {
        this.back.emit(this.form.getRawValue());
    }

    public next(enquiry = false): void {
        if (this.timerSub) this.timerSub.unsubscribe();

        this.showMessage = false;
        this.error = true;
        this.form.markAllAsTouched();

        if (this.form.invalid || this.people <= 0) {
            this.showMessage = true;
            this.timerSub = timer(3000).subscribe(() => this.showMessage = false);

            return;
        }

        this.createBooking.emit({ form: this.form.getRawValue(), priceOptions: this.priceOptions, enquiry });
    }

    public calculateTotal(options?: any[]): void {
        if (options) this.priceOptions = options;

        this.total = this.priceOptions.reduce((acc, item) => acc + (item.qty_requested * (item.price_calc || item.price)), 0);
        this.fee = this.dirs.fees.reduce((acc, item) => item.min < this.total ? item.fee : acc, 0);
        this.people = this.priceOptions.reduce((acc, item) => acc + item.qty_requested, 0);
    }

    public applyPromoVoucher(): void {
        this.calculateTotal();

        if (!this.session.promo_id) return;

        this.http.get('/promos', {
            params: {
                promo_id: this.session.promo_id,
                no_intercept_response: '1'
            }
        }).subscribe(res => {
            const promo = this.applyPromo(res.body.promos[0]);

            this.apply.emit({ type: 'promo', item: promo });

            this.calculateTotal();
        });
    }

    private applyPromo(promo: any): any {
        const res = applyPromo(promo, this.date, this.venue, this.package, this.session, this.priceOptions, !!this.session.promo_id);

        console.log(res);

        if (typeof res === 'string') return;

        this.apply.emit({ type: 'priceOptions', item: res });

        if (promo) return { promo, id: promo.promo_id, code: promo.promocode, desc: promo.desc };
    }

    public get hint(): string {
    	if (this.people <= 0) return 'hints.add_participants';
        if (this.form.get('first_name').invalid) return 'hints.fill_in_name';
        if (this.form.get('customer_email').invalid) return 'hints.provide_valid_email';
        if (this.form.get('customer_tel').invalid) return 'hints.phone';

	if (this.form.get('terms').invalid) return 'Accept Terms';
        return '';
    }
}
