<div class="wcs-flex wcs-items-center wcs-w-full wcs-flex-wrap">
    <div class="wcs-w-full md:wcs-w-1/3 wcs-flex wcs-items-center">
        <p class="wcs-font-semibold wcs-mr-2">{{ option.name }}</p> <span class="wcs-font-light" *ngIf="option.label">({{ option.label }})</span>
    </div>
    <div class="wcs-w-1/2 md:wcs-w-1/3 md:wcs-justify-center wcs-flex wcs-items-start">
        <div class="wcs-flex wcs-border wcs-border-accent" [ngClass]="error && people <= 0 ? 'wcs-border-red-700 wcs-text-red-700' : 'wcs-border-accent wcs-text-accent'">
            <button (click)="changeOption(option, -1)" type="button" class="wcs-h-6 wcs-w-6 wcs-flex wcs-items-center wcs-justify-center wcs-text-md wcs-hover-bg">
                <fa-icon [icon]="faMinus"></fa-icon>
            </button>
            <input class="wcs-h-6 wcs-w-6 wcs-text-center wcs-font-medium wcs-text-lg" type="text" [value]="option.qty_requested" disabled />
            <button (click)="changeOption(option, +1)" type="button" class="wcs-h-6 wcs-w-6 wcs-flex wcs-items-center wcs-justify-center wcs-text-md wcs-hover-bg">
                <fa-icon [icon]="faPlus"></fa-icon>
            </button>
        </div>
    </div>
    <div class="wcs-w-1/2 md:wcs-w-1/3 wcs-text-right">
        <p>{{ (option.price_calc || option.price) | currency:currency:'symbol-narrow' }}{{ 'price.per_person' | translate }}</p>
    </div>
</div>