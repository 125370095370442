<div class="wcs-w-100 wcs-relative wcs-rounded" *ngIf="!loading">
    <div class="wcs-w-full wcs-flex wcs-items-center wcs-p-4 wcs-text-white wcs-bg-accent wcs-rounded-t">
        <div class="wcs-flex-1">
            <p class="wcs-text-lg wcs-font-medium">{{ 'session_table.time_slot' | translate }}</p>
        </div>
        <div class="wcs-flex-1 wcs-hidden sm:wcs-block">
            <p class="wcs-text-lg wcs-font-medium">{{ 'session_table.package_name' | translate }}</p>
        </div>
        <div class="wcs-flex-1 wcs-hidden sm:wcs-block" *ngIf="showSpaces">
            <p class="wcs-text-lg wcs-font-medium">{{ 'session_table.pax' | translate }}</p>
        </div>
        <div class="wcs-flex-1 wcs-hidden sm:wcs-block">
            <p class="wcs-text-lg wcs-font-medium">{{ 'session_table.price' | translate }}</p>
        </div>
        <div class="wcs-flex-1 wcs-hidden sm:wcs-block"></div>
    </div>
    <div class="wcs-w-full wcs-border wcs-border-t-0 wcs-border-accent wcs-rounded-b">
        <div class="wcs-w-full wcs-flex wcs-flex-col sm:wcs-flex-row wcs-items-center wcs-justify-between wcs-p-4 wcs-table-divisor">
            <div class="sm:wcs-w-3/12 wcs-w-full wcs-mb-2 sm:wcs-mb-0">
                <label class="wcs-text-gray-700 wcs-mr-2 wcs-text-sm wcs-block" for="package">Package</label>
                <ng-select
                    class="wcs-custom"
                    id="package"
                    bindLabel="name"
                    bindValue="package_id"
                    [placeholder]="'misc.all_packages' | translate"
                    name="packages"
                    aria-label="packages"
                    [items]="packages"
                    [closeOnSelect]="false"
                    [searchable]="false"
                    [multiple]="true"
                    (change)="filterPackages()"
                    [(ngModel)]="selectedPackages"
                >
                    <ng-template ng-option-tmp let-item="item">
                        <p [class.wcs-opacity-50]="item.unavail" class="wcs-leading-normal wcs-font-medium wcs-text-gray-700">
                            {{ item.name }} <span *ngIf="item.unavail">({{ item.available }}+)</span>
                        </p>
                    </ng-template>
                </ng-select>
            </div>
            <div class="sm:wcs-w-auto wcs-w-full wcs-flex wcs-flex-col wcs-relative">
                <label class="wcs-text-gray-700 wcs-mr-2 wcs-text-sm" for="date">Date</label>
                <input
                    [min]="min"
                    [value]="date | date:'E, dd MMM yyyy'"
                    name="date"
                    id="date"
                    class="wcs-rounded wcs-bg-transparent sm:wcs-w-48 wcs-w-full wcs-border wcs-border-accent wcs-p-2 wcs-text-accent"
                    (click)="calendarOpen = true"
                    readonly
                />
                <div class="wcs-calendar wcs-shadow-lg wcs-border wcs-rounded-lg wcs-overflow-hidden wcs-z-10" *ngIf="calendarOpen">
                    <app-calendar
                        [venueId]="venueId"
                        [packageIds]="selectedPackages"
                        [selectable]="selectable"
                        (dateChange)="getData($event)"
                    ></app-calendar>
                </div>
                <!-- <fa-icon class="wcs-text-xl wcs-text-accent wcs-absolute" style="right: 0.5rem" [icon]="faCalendar"></fa-icon> -->
            </div>
        </div>
        <div class="wcs-w-full wcs-p-4 wcs-text-gray-700" *ngIf="!filteredSession.length">
            <p class="wcs-text-center">{{ 'misc.no_avail' | translate }}</p>
        </div>
        <div class="wcs-w-full wcs-p-4 wcs-text-orange-500 wcs-border-b wcs-font-bold" *ngIf="showHeader">
            <p class="wcs-text-center">No Availability for Today - Showing {{ date | date:'E, d MMM yyyy' }}</p>
        </div>
        <ng-container *ngFor="let group of filteredSession">
            <div
                class="wcs-w-full wcs-flex sm:wcs-items-center sm:wcs-flex-nowrap wcs-flex-wrap wcs-items-start wcs-p-4 wcs-text-gray-700"
                *ngFor="let session of group; index as i"
                [class.wcs-table-divisor]="session.border"
                (click)="session.full ? null : select(session)"
            >
                <!-- (click)="session.full ? null : selectItem.emit(session)" -->
                <div class="sm:wcs-flex-1 wcs-w-full sm:wcs-w-auto">
                    <p class="wcs-font-semibold">{{ i === 0 ? session.time_slot : '' }}</p>
                </div>
                <div class="sm:wcs-flex-1 wcs-mr-2 sm:wcs-mr-0">
                    <p class="wcs-font-mdeium">{{ session.package_name }}</p>
                </div>
                <div class="sm:wcs-flex-1 sm:wcs-w-auto wcs-w-3/4" *ngIf="showSpaces">
                    <p>{{ session.spaces }}</p>
                </div>
                <div class="sm:wcs-flex-1 wcs-w-auto wcs-flex wcs-items-center">
                    <p>{{ 'price.from' | translate }} {{ session.price | currency:session.currency:'symbol-narrow' }}{{ 'price.per_person' | translate }}</p>
                    <div class="wcs-ml-4 wcs-p-1 wcs-font-medium wcs-rounded wcs-text-white wcs-bg-green-500 wcs-text-xs" *ngIf="session.promo_id">
                        -{{ session.promo_fixed ? (session.promo_fixed | currency:session.currency) : (session.promo_percent + '%') }}
                    </div>
                </div>
                <div class="sm:wcs-flex-1 wcs-text-right wcs-w-full sm:wcs-w-auto">
                    <fa-icon [icon]="faShoppingCart" class="wcs-mr-4" *ngIf="!session.full && selectedComponent && selectedComponent.package_id === session.session.package_id && selectedComponent.session_id === session.session.session_id"></fa-icon>
                    <button
                        type="button"
                        [class.wcs-pointer-events-none]="session.full"
                        [class.wcs-full-btn]="session.full"
                        [class.wcs-availabilee-btn]="!session.full"
                        [class.wcs-pointer-events-none]="session.full"
                        [style.background-color]="session.full ? '#800000' : '#476b00'"
                        class="wcs-w-20 wcs-font-medium wcs-py-2 wcs-text-center wcs-px-4 wcs-rounded wcs-text-white"
                    >
                        <ng-container *ngIf="session.full">{{ noavail }}</ng-container>
                        <ng-container *ngIf="!session.full">{{ 'buttons.book' | translate }}</ng-container>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="loadingOverlay" class="wcs-absolute wcs-inset-0 wcs-flex wcs-items-start wcs-py-16 wcs-justify-center" style="background: rgba(255, 255, 255, 0.5)">
        <div class="wcs-w-16 wcs-h-16 wcs-border-blue-500 wcs-border-t-2 wcs-rounded-full wcs-spin"></div>
    </div>
</div>
<div class="wcs-fixed wcs-inset-0 wcs-flex wcs-items-center wcs-justify-center" style="background: rgba(0, 0, 0, 0.5);" *ngIf="modal">
    <div class="wcs-w-2/6 wcs-bg-white wcs-p-5 wcs-rounded">
        <p class="wcs-text-gray-700 wcs-mb-4">This bookings is for {{ modal === 1 ? 'later today' : 'tomorrow' }}. Is that correct?</p>
        <div class="wcs-w-full wcs-flex wcs-items-center wcs-justify-end">
            <a href="javascript:void(0)" class="wcs-p-2 wcs-text-green-600 wcs-mr-2 wcs-font-medium" (click)="selectItem.emit(selectedSession)">Yes</a>
            <a href="javascript:void(0)" class="wcs-p-2 wcs-text-red-600 wcs-font-medium" (click)="modal = 0">No</a>
        </div>
    </div>
</div>
