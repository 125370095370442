import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { switchMap, shareReplay, retryWhen, mergeMap } from 'rxjs/operators';

import { HttpConfig } from '../models/http-config.model';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    public baseURL: string;
    public mode: string;
    public lang: string;
    public appKey: string;
    public apiKey: string;
    public publicKey: Observable<string>;
    public params = { origin_app: '', origin_cta: '' };

    constructor(
        private http: HttpClient
    ) { }

    public get isTestMode(): boolean {
        return this.mode === 'test';
    }

    public getPublicKey(): Observable<string> {
        if (this.publicKey) return this.publicKey;

        const key = this.getKeyFromLocalStorage();

        if (key) return of(key);

        this.publicKey = this.http.get<any>(`${this.baseURL}/auths?access=public`, {
            observe: 'response',
            headers: {
                'X-API-KEY': this.apiKey,
                'X-APP-KEY': this.appKey
            }
        }).pipe(
            switchMap(data => {
                localStorage.setItem('publicKey', data.body.auths.jwt);

                return of(data.body.auths.jwt);
            }),
            shareReplay(1)
        );

        return this.publicKey;
    }

    public get(url: string, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.baseURL}${url}`, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retryWhen(errors => errors.pipe(
                mergeMap((err, i) => {
                    if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(err);

                    localStorage.removeItem('publicKey');
                    this.publicKey = undefined;

                    return timer(2000 * i);
                })
            ))
        );
    }

    public post(url: string, body: any, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retryWhen(errors => errors.pipe(
                mergeMap((err, i) => {
                    if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(err);

                    localStorage.removeItem('publicKey');
                    this.publicKey = undefined;

                    return timer(2000 * i);
                })
            ))
        );
    }

    public patch(url: string, body: object, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.patch<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retryWhen(errors => errors.pipe(
                mergeMap((err, i) => {
                    if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(err);

                    localStorage.removeItem('publicKey');
                    this.publicKey = undefined;

                    return timer(2000 * i);
                })
            ))
        );
    }

    public put(url: string, body: object, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.put<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retryWhen(errors => errors.pipe(
                mergeMap((err, i) => {
                    if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(err);

                    localStorage.removeItem('publicKey');
                    this.publicKey = undefined;

                    return timer(2000 * i);
                })
            ))
        );
    }

    public delete(url: string, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.baseURL}${url}`, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retryWhen(errors => errors.pipe(
                mergeMap((err, i) => {
                    if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(err);

                    localStorage.removeItem('publicKey');
                    this.publicKey = undefined;

                    return timer(2000 * i);
                })
            ))
        );
    }

    public enocodeJSON(element: any, key?: string, list?: any[]): string {
        list = list || [];

        if (typeof (element) === 'object') {
            for (const idx in element) this.enocodeJSON(element[idx], key ? key + '[' + idx + ']' : idx, list);
        } else {
            list.push(key + '=' + encodeURIComponent(element));
        }

        return list.join('&');
    }

    private parseConfig(config?: HttpConfig): HttpConfig {
        const newConfig: HttpConfig = {
            headers: { 'X-APP-KEY': this.appKey }
        };

        newConfig.params = this.filterParams({
            ...(config ? config.params : {}),
            ...this.params,
            lang: this.lang,
            lang_single: '1'
        });

        if (config && config.headers) newConfig.headers = { ...newConfig.headers, ...this.filterParams(config.headers) };
        if (config && config.body) newConfig.body = config.body;

        return newConfig;
    }

    private filterParams(params: { [key: string]: any }): any {
        const data: any = {};

        Object.keys(params).forEach(key => {
            if (!params.hasOwnProperty(key) || !params[key]) return;

            if (params[key] === true || params[key] === false) data[key] = params[key] ? '1' : '0';
            else data[key] = params[key];
        });

        return data;
    }

    private getKeyFromLocalStorage(): string {
        const key = localStorage.getItem('publicKey');

        try {
            JSON.parse(key);

            return null;
        } catch (err) {
            return key;
        }
    }
}
