import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    OnDestroy,
    AfterViewInit,
    EventEmitter,
    Output,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { from, Observable, of, throwError, Subject, Subscription, combineLatest, interval, timer } from 'rxjs';
import { switchMap, finalize, tap, catchError, debounceTime } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { omit, pick } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment-timezone';

import { Availability } from 'src/app/models/venues.model';
import { HttpService } from 'src/app/services/http.service';
import { PaymentService } from 'src/app/services/payment.service';
import { applyPromo } from 'src/app/helpers/price-options.helpers';

declare var paypal: any;
//declare var fbq: any;

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html'
})
export class PaymentsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('paypalEl', { static: true }) paypalRef: ElementRef;
    @ViewChild('nativeEl', { static: true }) nativeRef: ElementRef;
    @ViewChild('cardEl', { static: true }) cardRef: ElementRef;

    @Output() back: EventEmitter<void> = new EventEmitter();
    @Output() reset: EventEmitter<void> = new EventEmitter();
    @Output() next: EventEmitter<void> = new EventEmitter();
    @Output() apply: EventEmitter<{ type: string, item: any }> = new EventEmitter();
    @Output() redirect: EventEmitter<void> = new EventEmitter();

    @Input() package: any;
    @Input() duration: number;
    @Input() message: string;
    @Input() session: Availability;
    @Input() date: Date;
    @Input() formData: any;
    @Input() booking: any;
    @Input() priceOptions: any[] = [];
    @Input() dirs: any;
    @Input() promo: any;
    @Input() voucher: any;
    @Input() venue: any;

    public faChevronLeft = faChevronLeft;

    public form: UntypedFormGroup;
    public options = [];
    public option = 'full';
    public total = 0;
    public fee = 0;
    public timer = '00:00';
    public loading = false;
    public promoLoading = false;
    public recalc = false;
    public showPaymentRequest = false;
    public requestValidation = false;
    public showModal = false;
    public availLoading = false;
    public cardHidden = true;
    public cardError = '';
    public dueBy: string;
    public people = 0;
    public promocode = '';
    public validation = new UntypedFormControl('', Validators.required);
    public checkVoucher: Subject<void> = new Subject();
    public surcharge = 0;
    public paymentMethods = 0;

    private paymentRequestInstance: any;
    private paymentButton: any;
    private card: any;
    private cardHandler = this.onChange.bind(this);
    private checkVoucherSub: Subscription;
    private timerSub: Subscription;

    constructor(
        private http: HttpService,
        private payment: PaymentService,
        private cd: ChangeDetectorRef,
        private builder: UntypedFormBuilder,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        if (this.promo) {
            const res = this.applyPromo(this.promo.promo);

            if (!res) setTimeout(() => this.removePromo());
        }

        this.form = this.builder.group({
            name: [this.booking.customer_name, Validators.required],
            email: [this.booking.customer_email, [Validators.required, CustomValidators.email]],
            card_zip: ['']
        });

        this.checkVoucherSub = this.checkVoucher.subscribe(() => {
            if (this.voucher && this.voucher.redemption_code.startsWith('psc')) {
                this.applyPromoVoucher(false, this.voucher.redemption_code);
            }
        });

        if (this.session.promo_id && !this.promo) this.applyPromoVoucher(false, this.session.promo_id, 'promo_id');

        if (+this.duration) this.startTimer();
    }

    ngAfterViewInit() {
        this.initPaypal();
        this.initStripe();
        this.initNative();

        setTimeout(() => this.cardHidden = this.processors.includes('paypal') || this.showPaymentRequest);

        this.paymentMethods = [
            this.processors.includes('paypal'),
            this.processors.includes('stripe'),
            this.showPaymentRequest
        ].filter(Boolean).length;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.priceOptions && changes.priceOptions.currentValue && changes.priceOptions.firstChange) this.calculateTotal();
        if (changes.voucher || changes.promo) this.calculateTotal();
    }

    ngOnDestroy() {
        if (this.paymentButton) this.paymentButton.destroy();
        if (this.checkVoucherSub) this.checkVoucherSub.unsubscribe();
        if (this.timerSub) this.timerSub.unsubscribe();

        if (this.card) {
            this.card.destroy();
            this.card.removeEventListener('change', this.cardHandler);
        }
    }

    public get processors(): string[] {
        return this.payment.profiles;
    }

    public get selectedOption(): any {
        if (!this.option) return;

        return this.options.find(item => item.val === this.option);
    }

    public get dueNow(): number {
        let total = this.selectedOption ? this.selectedOption.price + this.fee : this.total + this.fee;

        if (this.voucher) total -= +this.voucher.balance_value;
        if (total < 0) total = 0;

        return total;
    }

    public get hint(): string {
        const { name } = this.form.getRawValue();

        if (this.people <= 0) return 'hints.add_participants';
        if (!name) return 'hints.fill_in_name';

        return 'hints.provide_valid_email';
    }

    public resetTimer(): void {
        if (this.availLoading) return;
        this.availLoading = true;

        timer(2000).pipe(
            finalize(() => this.availLoading = false)
        ).subscribe(() => this.startTimer());
    }

    public calculateTotal(options?: any[]): void {
        if (options) {
            this.priceOptions = options;

            this.checkVoucher.next();
        }

        this.people = this.priceOptions.reduce((acc, item) => acc + item.qty_requested, 0);

        const month = moment(this.date).format('M');
        const day = +moment(this.date).format('D');

        const holiday = (this.venue.holidays || []).find(item => (
            item.day === day &&
            item.month === month &&
            (!item.package_ids.length || item.package_ids.includes(this.package.package_id))
        ));

        this.total = this.priceOptions.reduce((acc, item) => {
            return acc + (item.qty_requested * (this.promo ? (item.price_calc || item.price) : item.price))
        }, 0);

        if (holiday && holiday.surcharge) {
            if (holiday.surcharge_type === 'pp') this.surcharge += holiday.surcharge * this.people;
            if (holiday.surcharge_type === 'pb') this.surcharge += holiday.surcharge;
            if (holiday.surcharge_type === 'percentage') this.surcharge += this.total * (1 + holiday.surcharge / 100);
        }

        this.total += this.surcharge;

        this.checkDeposit();

        let total = this.selectedOption ? this.selectedOption.price : this.total;

        if (this.voucher) total -= +this.voucher.balance_value;
        if (total < 0) total = 0;

        this.fee = this.dirs.fees.reduce((acc, item) => item.min < total ? item.fee : acc, 0);

        // if (this.promo) this.applyPromo(this.promo.promo);

        if (!this.paymentRequestInstance) return;

        this.paymentRequestInstance.update({
            total: {
                label: `${this.package.name}, ${this.package.venue.name}`,
                amount: Math.round(this.dueNow * 100)
            }
        });
    }

    public applyPromoVoucher(showError = true, code = '', field = 'promocode'): void {
        if (this.promoLoading || (!this.promocode && !code)) return;

        if (!code) this.promoLoading = true;
        else this.recalc = true;

        const invalid = { voucher: '', promo: '' };

        this.http.get('/promos', {
            params: {
                [field]: code || this.promocode,
                no_intercept_response: '1'
            }
        }).pipe(
            tap(res => {
                this.applyPromo(res.body.promos[0]);

                this.promocode = '';
            }),
            catchError((err) => {
                invalid.promo = 'Promocode is invalid';

                const fields = [
                    'price_options',
                    'component_id',
                    'session_id',
                    'package_id',
                    'venue_id',
                    'activity_id',
                    'booking_id',
                    'start_date',
                    'qty_requested',
                    'price',
                    'pax'
                ];

                const components = this.http.enocodeJSON({
                    components: this.booking.components.map(item => ({
                        ...pick(item, fields),
                        price_options: this.priceOptions.map(val => omit(val, ['sessions', 'dow'])),
                        total_cost: this.total
                    }))
                }).split('&').reduce((acc, item) => {
                    const [key, value] = item.split('=');
                    return { ...acc, [key]: value };
                }, {});

                if (field === 'promo_id') return of(null);

                return this.http.get('/vouchers', {
                    params: {
                        _form: 'iqvalidate',
                        redemption_code: code || this.promocode,
                        currency: this.booking.currency,
                        booking_id: this.booking ? this.booking.booking_id : null,
                        value: this.total.toString(),
                        no_intercept_response: '1',
                        widget: '1',
                        ...components
                    }
                });
            }),
            tap(res => {
                if (!res || !res.body.validation) return;

                invalid.promo = '';

                if (res.body.validation && !res.body.validation.valid) {
                    return invalid.voucher = res.body.validation ? res.body.validation.message : 'Voucher is invalid';
                }

                if (showError) this.toastr.success('Voucher applied');

                const voucher = res.body.vouchers[0];

                this.validation.setValidators([Validators.required, CustomValidators.notEqual(voucher.redemption_code) as any]);

                this.apply.emit({ type: 'voucher', item: voucher });
                this.requestValidation = voucher.redemption_code.toLowerCase().startsWith('gr');
            }),
            finalize(() => {
                if (invalid.voucher && invalid.promo && showError) this.toastr.error('Invalid promo or voucher code');
                else if ((invalid.voucher || invalid.promo) && showError) this.toastr.error(invalid.voucher || invalid.promo);

                this.promoLoading = false;
                this.recalc = false;
            })
        ).subscribe();
    }

    public removePromo(): void {
        this.apply.emit({ type: 'promo', item: null });

        this.applyPromo(null);
        setTimeout(() => this.calculateSurcharge());
    }

    public removeVoucher(): void {
        this.apply.emit({ type: 'voucher', item: null });
    }

    public makeTransaction(token?: { id: string }, paymentMethod = 'card', ev?: any, force = false): void {
        if (this.requestValidation) this.validation.markAsTouched();
        if (this.requestValidation && this.validation.invalid) return;

        if (this.loading && !force) return;
        this.loading = true;

        const form = this.form.getRawValue();
        const headers = { 'X-API-KEY': this.booking.guest_key };
        let transaction: any;

        combineLatest([
            this.http.put('/bookings', {
                booking_id: this.booking.booking_id,
                originally: 'booking'
            }, { headers }),
            this.http.put('/components', {
                ...this.booking.components[0],
                price_options: this.priceOptions,
                promo_id: this.promo ? this.promo.promo.promo_id : null
            }, { headers })
        ]).pipe(
            switchMap(() => this.voucherTransaction()),
            switchMap(() => token ? of(token) : this.getToken(form)),
            switchMap(res => {
                if (!res) return of(null);

                return this.http.post('/transactions', {
                    booking_id: this.booking.booking_id,
                    payment_method: paymentMethod,
                    portal_ref: res.id,
                    total_amount: this.dueNow,
                    transaction_charge: this.fee,
                    portal_uid: paymentMethod === 'card' ? this.payment.stripeKey : this.payment.paypalKey,
                    name: form.name,
                    email: form.email,
                    card_zip: form.card_zip,
                    note: form.note || null,
                    save: 1,
                    currency: this.booking.currency,
                    source: 'widget.session-bookit.public',
                    status: this.session.i_id ? 'captured' : 'pending',
                    _return: 'booking'
                }, { headers });
            }),
            switchMap(res => {
                if (!res) return of(null);

                transaction = res.body.transactions[0];

                if (!res.body['3d_secure'] || res.body['3d_secure'].action === 'success') return of(res);

                return this.payment.stripe.handleCardAction(res.body['3d_secure'].payment_intent_client_secret);
            }),
            switchMap((res: any) => {
                if (!res) return of(null);

                if (res.error) return throwError({ type: 'card_error', message: res.error.message });
                if (res.status) return of(res);

                return this.http.post('/transactions', { ...transaction, _return: 'booking' }, { headers });
            }),
            catchError(err => {
                if (err.type || err instanceof HttpErrorResponse) return throwError(err);

                return of(null);
            }),
            finalize(() => this.loading = false)
        ).subscribe(() => {
            if (ev) ev.complete('success');

            this.track(transaction);

            if (this.package.booking_fields.length) this.next.emit();
            else this.redirect.emit();
        }, (err) => {
            if (ev) ev.complete('fail');

            if (err.type) this.toastr.error(err.message);
            else if (err.error && err.error.errors) err.error.errors.forEach(item => this.toastr.error(item.message));
            else this.toastr.error('Payment Unsuccessful, please check your details and try again');
        });
    }

    public toStart(): void {
        this.showModal = false;

        this.reset.emit();
    }

    private onChange({ error, value }: { error: Error, value: any }): void {
        if (error) this.cardError = error.message;
        else this.cardError = null;

        this.form.get('card_zip').setValue(value.postalCode, { emitEvent: false });

        this.cd.detectChanges();
    }

    private initPaypal(): void {
        if (!this.processors.includes('paypal')) return;

        paypal.Buttons({
            createOrder: (_, actions) => {
                this.loading = true;

                return actions.order.create({
                    purchase_units: [{
                        amount: { value: this.dueNow },
                        description: this.booking.booking_id
                    }]
                });
            },
            onApprove: (data, actions) => {
                from(actions.order.authorize()).subscribe(res => {
                    this.makeTransaction({ id: data.orderID }, 'paypal', null, true);
                });
            },
            onCancel: () => this.loading = false,
            onError: error => {
                console.log(error);
                this.loading = false;
            }
        }).render(this.paypalRef.nativeElement);
    }

    private initStripe(): void {
        if (!this.processors.includes('stripe')) return;

        this.card = this.payment.elements.create('card', {
            style: {
                base: {
                    lineHeight: '18px',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: 'rgba(110, 110, 110, 0.6)',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '18px'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            },
            hidePostalCode: true
        });

        this.card.mount(this.cardRef.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }

    private startTimer(): void {
        if (this.timerSub) this.timerSub.unsubscribe();

        this.showModal = false;

        this.timer = `${('0' + Math.floor(+this.duration * 60 / 60)).slice(-2)}:${('0' + Math.floor(+this.duration * 60 % 60)).slice(-2)}`

        const timerDuration = this.duration * 60 - 1;

        this.timerSub = interval(1000).subscribe(duration => {
            const currentTime = timerDuration - duration;
            const minutes = ('0' + Math.floor(currentTime / 60)).slice(-2);
            const seconds = ('0' + Math.floor(currentTime % 60)).slice(-2);

            if (currentTime <= 0) this.refreshAvailability();
            else this.timer = `${minutes}:${seconds}`;
        });
    }

    private refreshAvailability(): void {
        this.timerSub.unsubscribe();

        this.showModal = true;
    }

    private async initNative(): Promise<void> {
        if (!this.processors.includes('stripe')) return;

        this.paymentRequestInstance = this.payment.stripe.paymentRequest({
            country: 'GB',
            currency: this.booking.currency.toLowerCase(),
            total: {
                label: this.package.name,
                amount: Math.round(this.dueNow * 100),
            },
            displayItems: this.priceOptions.filter(item => item.qty_requested).map(item => ({
                label: item.name,
                amount: item.price * item.qty_requested * 100
            })),
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true
        });

        this.paymentButton = this.payment.elements.create('paymentRequestButton', {
            paymentRequest: this.paymentRequestInstance,
            style: {
                paymentRequestButton: { type: 'buy', theme: 'light', height: '45px' }
            }
        });

        this.showPaymentRequest = await this.paymentRequestInstance.canMakePayment();

        if (!this.showPaymentRequest) return;

        this.paymentButton.mount(this.nativeRef.nativeElement);

        this.paymentRequestInstance.on(this.payment.secure ? 'paymentmethod' : 'token', ev => {
            if (ev.error) return ev.complete('fail');
            this.makeTransaction(ev[this.payment.secure ? 'paymentMethod' : 'token'], 'card', ev);
        });
    }

    private voucherTransaction(): Observable<any> {
        if (!this.voucher) return of(null);

        const amount = (this.selectedOption ? this.selectedOption.price : this.total);

        return this.http.post('/transactions', {
            booking_id: this.booking.booking_id,
            currency: this.booking.currency,
            payment_method: 'voucher',
            redemption_code: this.voucher.redemption_code,
            note: this.validation.value,
            total_amount: amount < +this.voucher.balance_value ? amount : this.voucher.balance_value
        });
    }

    private getToken(form: any): Observable<any> {
        if (this.dueNow <= 0) return of(null);

        if (!this.payment.secure) {
            return from(this.payment.stripe.createToken(this.card)).pipe(
                switchMap(({ token, error }: any) => error ? throwError(error) : of (token))
            );
        }

        return from(this.payment.stripe.createPaymentMethod('card', this.card, {
            billing_details: { name: form.name }
        })).pipe(
            switchMap(({ paymentMethod, error }) => error ? throwError(error) : of (paymentMethod))
        );
    }

    private checkDeposit(): any {
        if (!+this.package.deposit || !this.package.deposit_lead) return;

        let totalDepositValue = 0;

        if (this.package.deposit_type === '4') totalDepositValue = this.total * (+this.package.deposit / 100);
        else if (this.package.deposit_type === '3' || this.package.deposit_type === '2') totalDepositValue = +this.package.deposit;
        else totalDepositValue = +this.package.deposit * this.people;

        if (+this.package.deposit_min && +this.package.deposit_min > totalDepositValue) totalDepositValue = +this.package.deposit_min;
        if (this.total <= totalDepositValue || !totalDepositValue) return;

        const start = moment(this.date).startOf('day');
        const deadline = start.clone().subtract(+this.package.deposit_lead, 'days').startOf('day');

        if (moment().startOf('day').isAfter(deadline)) return;

        const dueBy = this.package.payment_due ? moment(this.date).subtract(+this.package.payment_due, 'days') : deadline;

        if (start.isSame(dueBy)) this.dueBy = 'Remaining balance due on arrival';
        else if (moment().startOf('day').isSame(dueBy)) this.dueBy = 'Remaining balance due today';
        else this.dueBy = `Remaining balance due by ${dueBy.format('Do MMM yyyy')}`;

        this.options = [
            { val: 'full', name: 'Pay in Full', price: this.total },
            { val: 'deposit', name: 'Pay Deposit', price: totalDepositValue }
        ];
    }

    private applyPromo(promo: any): any {
        const res = applyPromo(
            promo,
            this.date,
            this.venue,
            this.package,
            this.session,
            this.priceOptions,
            this.people,
            promo && this.session.promo_id === promo.promo_id
        );

        if (typeof res === 'string') {
            this.toastr.error(res);

            if (this.promo) this.removePromo();

            return;
        }

        if (!promo) return;

        const applied = { promo, id: promo.promo_id, code: promo.promocode, desc: promo.desc };

        this.apply.emit({ type: 'promo', item: applied });
        this.apply.emit({ type: 'priceOptions', item: res });

        this.calculateSurcharge(applied);

        this.toastr.success('Discount applied');

        return applied;
    }

    private calculateSurcharge(promo?: any): void {
        this.surcharge = 0;

        if (!promo) promo = this.promo;
        if (!promo || !promo.promo.price_variations || !promo.promo.price_variations.length) return this.calculateTotal();

        const date = moment(this.date).format('YYYY-MM-DD');
        const day = moment(this.date).format('dddd');

        this.surcharge = promo.promo.price_variations.reduce((acc, item) => {
            if (!item.days.includes(day) && item.date !== date) return acc;

            return acc < item.surcharge ? item.surcharge : acc;
        }, 0);

        this.calculateTotal();
    }

    private track(transaction: any): void {
        try {
            localStorage.removeItem('wcs-booking');

            // tslint:disable-next-line: no-string-literal
            window['dataLayer'].push({
                event: 'conversion_booking',
                conversion_type: 'booking',
                order_id: `${this.booking.booking_id}_${transaction.transaction_id}`,
                value: this.booking.total_cost,
                label: 'booking',
                currency: this.booking.currency,
                product_ids: `v${this.package.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`,
                category: 'conversion',
                action: 'submitted'
            });

            /*if (typeof fbq !== 'undefined') {
                fbq('track', 'Purchase', {
                    value: this.booking.total_cost,
                    currency: this.booking.currency,
                    content_category: 'booking',
                    content_type: 'product',
                    contents: [
                        {
                          id: `v${this.package.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`,
                          quantity: this.booking.qty_requested
                        }
                    ],
                    product_ids: `v${this.package.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`
                });
            }*/
        } catch (err) { }
    }
}
