import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime-ex';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarModule, DateAdapter, CalendarDateFormatter, CalendarMomentDateFormatter, MOMENT } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';

import { LOCAL_STORAGE_PROVIDERS } from './services/local-storage.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { GeronigoBookitComponent } from './components/geronigo-bookit/geronigo-bookit.component';
import { SessionsTableComponent } from './components/sessions-table/sessions-table.component';
import { CustomerDataComponent } from './components/customer-data/customer-data.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { PaymentService } from './services/payment.service';
import { PriceOptionComponent } from './components/price-option/price-option.component';
import { BookingFieldsComponent } from './components/booking-fields/booking-fields.component';
import { VenuePickerComponent } from './components/venue-picker/venue-picker.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { GlobalErrorHandler } from './services/global-error-handler.service';

moment.updateLocale('en', { week: { dow: 1, doy: 0 } });

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'https://apidev.rezbot.com/config/lang/', '.json');
}

const components = [
    GeronigoBookitComponent,
    SessionsTableComponent,
    CustomerDataComponent,
    PaymentsComponent,
    PriceOptionComponent,
    BookingFieldsComponent,
    VenuePickerComponent,
    CalendarComponent
];

@NgModule({ declarations: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgSelectModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ToastrModule.forRoot({ timeOut: 5000 }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: () => adapterFactory(moment)
        }, {
            dateFormatter: {
                provide: CalendarDateFormatter,
                useClass: CalendarMomentDateFormatter
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
        PaymentService,
        LOCAL_STORAGE_PROVIDERS,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
        { provide: MOMENT, useValue: moment },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
    constructor(private injector: Injector) { }

    ngDoBootstrap() {
        const elements: any[] = [
            [GeronigoBookitComponent, 'geronigo-session-bookit'],
            [GeronigoBookitComponent, 'rezbot-session-bookit'],
            [VenuePickerComponent, 'venue-picker'],
        ];

        for (const [component, name] of elements) {
            if (customElements.get(name)) continue;

            const el = createCustomElement(component, { injector: this.injector });

            customElements.define(name, el);
        }
    }
}
