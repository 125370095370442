import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, OnInit } from '@angular/core';
import { faCalendar, faPlus, faMinus, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment-timezone';
import { combineLatest, of } from 'rxjs';
import { finalize, tap, switchMap } from 'rxjs/operators';
import { groupBy, uniqBy, flatten } from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { HttpService } from 'src/app/services/http.service';
import { Package } from 'src/app/models/venues.model';
import { filterOptions, getOptionsMinMax } from 'src/app/helpers/price-options.helpers';

@Component({
    selector: 'app-sessions-table',
    templateUrl: './sessions-table.component.html',
})
export class SessionsTableComponent implements OnInit, OnChanges {
    @Input() booking: any;
    @Input() loading = true;
    @Input() date: Date = null;
    @Input() venueId: string;
    @Input() packageId: string;
    @Input() sessionId: string;
    @Input() noavail: string;
    @Input() selectable: string;
    @Input() showSpaces = false;

    @Output() toggleLoading: EventEmitter<boolean> = new EventEmitter();
    @Output() dateChange: EventEmitter<Date> = new EventEmitter();
    @Output() selectItem: EventEmitter<any> = new EventEmitter();
    @Output() setVenue: EventEmitter<any> = new EventEmitter();

    public faCalendar = faCalendar;
    public faPlus = faPlus;
    public faMinus = faMinus;
    public faShoppingCart = faShoppingCart;

    public min: Date = moment().startOf('day').toDate();
    public selectedPackages = [];
    public filteredSession: any[][] = [];
    public packages: Package[] = [];
    public sessions: any[][] = [];
    public loadingOverlay = false;
    public calendarOpen = false;
    public showHeader = false;
    public selectedComponent: any;
    public modal = 0;
    public selectedSession: any;

    private prevWidth = window.innerWidth;

    constructor(
        private http: HttpService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        if (this.date) this.setComponent();

        if(!this.packageId && this.selectable){
            this.packageId = this.selectable;
        }

        console.log(this.packageId);

        if(this.packageId) this.selectedPackages = this.packageId.split(',');

        if (this.venueId && this.packageId) this.getData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.venueId && changes.venueId.currentValue !== changes.venueId.previousValue) ||
            (changes.packageId && changes.packageId.currentValue !== changes.packageId.previousValue)
        ) this.getData();
    }

    @HostListener('window:resize')
    orderPackages(): void {
        if (
            (this.prevWidth < 800 && window.innerWidth >= 800) ||
            (this.prevWidth >= 800 && window.innerWidth < 800)
        ) this.filterPackages(false);

        this.prevWidth = window.innerWidth;
    }

    public changeOpt(session: any, val: number): void {
        if (val < 0 && session.qty_requested + val >= 0) session.qty_requested += val;
        else if (val > 0) session.qty_requested += val;
    }

    public filterPackages(doGet = true): void {
        const dates = this.packages
            .filter((item: any) => this.selectedPackages.includes(item.package_id) && item.unavail && item.a_first_available)
            .map((item: any) => +moment(item.a_first_available));

        if (dates.length && doGet) {
            const date = moment(Math.max(...dates)).toDate();

            return this.getData(date);
        }

        this.filteredSession = this.sessions.map(group => {
            if (!this.selectedPackages.length) return group;

            return group.filter(item => this.selectedPackages.includes(item.package.package_id));
        }).filter(item => item.length).sort((a, b) => {
            const aFull = a.filter(item => item.full).length === a.length;
            const bFull = b.filter(item => item.full).length === b.length;

            if (window.innerWidth < 800) {
                if (aFull && !bFull) return 1;
                if (!aFull && bFull) return -1;
            }

            if (a[0].start_time < b[0].start_time) return -1;
            if (a[0].start_time > b[0].start_time) return 1;

            return 0;
        });

        // setTimeout(() => {
        //     const firstButton = document.querySelector('.wcs-availabilee-btn');

        //     if (firstButton) firstButton.scrollIntoView({ behavior: 'smooth' });
        // });
    }

    public getData(newDate?: any): void {
        if (!this.loading) this.loadingOverlay = true;

        this.calendarOpen = false;

        if (newDate) {
            this.date = newDate;
            this.dateChange.emit(this.date);
        }else if(!this.date) {
            this.date = new Date();
        }
        (

            this.selectedPackages.length > 1 ?
                of(null) :
                this.http.get('/availabilities', {
                    params: {
                        next: '1',
                        venue_id: this.venueId,
                        package_id: this.selectedPackages.length > 0 ? this.selectedPackages.join(',') : this.packageId,
                        src:'getData1'
                    }
                })
        ).pipe(
            tap(res => {
                if (!res) return;
                if (!res.body.availabilities.length) return;

                const date = moment(res.body.availabilities[0].start_date).startOf('day').toDate();
                if (this.date && date.getTime() <= this.date.getTime()) return this.showHeader = false;
                if (date.getTime() !== +moment().startOf('day')) this.showHeader = true;

                if (newDate && this.date && this.date.getTime() !== +moment().add(1, 'day').startOf('day')) {
                    this.toastr.warning('Date Selected is Unavailable');
                }

                this.date = date;
                this.dateChange.emit(this.date);

                this.setComponent();
            }),
            switchMap(() => {
                const date = moment(this.date);

                return combineLatest([
                    this.http.get('/availabilities', {
                        params: {
                            venue_id: this.venueId,
                            start_date: date.format('YYYY-MM-DD'),
                            end_date: date.format('YYYY-MM-DD'),
                            package_id: this.packageId,
                            no_group: '1',
                            src:'getData'
                        }
                    }),
                    this.http.get('/venues', { params: { venue_id: this.venueId, _sub: 'none' } }),
                    this.http.get('/packages', { params: { venue_id: this.venueId, next_avail: '1' } }),
                    this.http.get('/companies', { params: { company_id: 'self' } }),
                    this.http.get('/sessions', {
                        params: {
                            venue_id: this.venueId,
                            start_date: date.format('YYYY-MM-DD'),
                            end_date: date.format('YYYY-MM-DD'),
                            per_package: '1'
                        }
                    })
                ]);
            }),
            finalize(() => {
                this.toggleLoading.emit(false);

                this.loadingOverlay = false;
            })
        ).subscribe(([availRes, venueRes, packagesRes, companiesRes, sessionsRes]) => {
            const selectable = (this.selectable || '').split(',').filter(Boolean);
            const venue = venueRes.body.venues[0];
            const sessions = sessionsRes.body.sessions;
            const company = companiesRes.body.companies[0];
            const localToday = moment().tz(venue.timezone);
            const isToday = moment(this.date).isSame(moment().startOf('day'));
            const availabilities = availRes.body.availabilities;

            const packs = packagesRes.body.packages.filter(item => (
                item.bookable === '1' &&
                item.active === '1' &&
                item.extra === '0' &&
                (!selectable.length || selectable.includes(item.package_id))
            ));

            const avails = flatten(packs.map(pack => {
                return sessions.filter(item => item.package_id === pack.package_id).map(item => {
                    const avail = availabilities.find(val => val.package_id === item.package_id && val.session_id === item.session_id);

                    return {
                        ...(avail || {}),
                        ...item,
                        unavail: !avail || avail.max_pax === '0' || avail.max_group === '0',
                        session_name: avail ? avail.session_name : item.name
                    };
                });
            })).filter((item: any) => {
                if (isToday && moment(item.start_time, 'HH:mm').tz(venue.timezone).isBefore(localToday)) return false;
                if (company.cms_config.sessions?.show_full) return true;

                return !item.unavail;
            }) as any;

            const grouped = groupBy(avails, item => item.session_name);

            this.setVenue.emit(venue);

            if (
                this.packageId &&
                packs.find(item => item.package_id === this.packageId) &&
                this.selectedPackages.length === 0
            ) this.selectedPackages = [this.packageId];

            this.packages = packs.filter(item => +item.bookable).map(item => {
                const avail = avails.find(val => val.package_id === item.package_id && val.unavail === false);

                return {
                    ...item,
                    unavail: !avail,
                    available: item.a_first_available ? moment(item.a_first_available).format('DD MMM') : null
                };
            }).filter(item => {
                if (item.bookable_from) return moment().isSameOrAfter(moment(item.bookable_from));

                return item.available;
            });

            if(this.packages.length <= 0){
                //no availability ? fetch the next 3 months so we get new data
                this.packages = packs;

                const date = moment(this.date);

                (this.http.get('/availabilities', {
                                            params: {
                                                /*next: '1',*/
                                                venue_id: this.venueId,
                                                package_id: this.selectedPackages.length > 0 ? this.selectedPackages.join(',') : this.packageId,
                                                src:'getData2',
                                                start_date: date.format('YYYY-MM-DD'),
                                                end_date: date.add(90,'days').format('YYYY-MM-DD')
                                            }
                                        })
                                        .pipe(
                                        tap(res => {
                                            if (!res) return;
                                            if(res.body.availabilities && res.body.availabilities[0])
                                                return this.getData(moment(res.body.availabilities[0].datetime.substring(0,10)).toDate()); //refetch for next date
                                            })
                                        )
                          ).subscribe((meh) => {})
                   return;

            }

            this.sessions = Object.values(grouped).map(group => {
                return group.map((item, i) => {
                    const pack = packs.find(val => val.package_id === item.package_id);
                    const priceOptions = filterOptions(pack, this.date, item);
                    const { min, max } = getOptionsMinMax(pack.currency || 'GBP', pack, this.date, item);

                    const mins = priceOptions.map(val => val.min_pax).filter(Boolean);
                    const maxs = priceOptions.map(val => val.max_pax).filter(Boolean);
                    const minPax = mins.length ? Math.min(...mins) : 1;
                    const maxPax = maxs.length ? Math.max(...maxs) : 100;

                    pack.max = max;

                    return {
                        time_slot: item.session_name || `${item.start_time} - ${item.end_time}`,
                        start_time: item.start_time,
                        package_name: pack.name,
                        price_options: priceOptions,
                        package: { ...pack, currency: venue.currency },
                        session: item,
                        border: group.length - 1 === i,
                        full: item.avail_status === 'closed' || item.unavail,
                        currency: venue.currency,
                        price: min,
                        spaces: `${minPax} - ${maxPax}`,
                        promo_id: item.promo_id,
                        promo_percent: item.promo_percent,
                        promo_fixed: item.promo_fixed,
                        max_pax: item.max_pax
                    };
                });
            });

            if (this.packageId && this.sessionId) {
                const data = flatten(this.sessions);
                const session = data.find((val) => val.session.package_id === this.packageId && val.session.session_id === this.sessionId);

                if (session) this.selectItem.emit(session);
            }

            this.packageId = null;

            //check if there is availability. if not, force recalculate

            if(!avails.length){
                this.filterPackages(true);
            }else{
                this.filterPackages(false);
            }

        });
    }

    public select(item: any): void {
        this.selectedSession = item;

        const date = moment(item.session.start_date).startOf('day');
        const today = moment().startOf('day');
        const diff = date.diff(today, 'days');

        if (diff === 0) this.modal = 1;
        else if (diff === 1) this.modal = 2;
        else this.selectItem.emit(item);
    }

    private setComponent(): void {
        if (!this.booking) return;

        const component = this.booking.components[0];
        const date = moment(component.start_date).startOf('day');

        if (date.isSame(this.date)) this.selectedComponent = component;
        else this.selectedComponent = null;
    }
}
