import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { loadScript } from "@paypal/paypal-js";
import { BehaviorSubject } from 'rxjs';

declare var Stripe: any;

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    public paypalLoaded = new BehaviorSubject(false);
    public stripeKey: string;
    public paypalKey: string;
    public paypalEnv: string;
    public paypal: any;
    public stripe: any;
    public elements: any;
    public secure = false;
    public initialized = false;
    public fees: { min: number, fee: number }[] = [];
    public profiles: string[] = [];
    public currency = 'GBP';

    constructor(
        private http: HttpService
    ) { }

    public init(p: string, res: any): void {
        this.initialized = true;

        const version = this.http.mode;
        const threshold = { ...res.booking_fee.threshold };
        const type = p || 'default';

        const profiles = res[type][version];

        this.fees = Object.keys(threshold).map(min => ({ min: +min, fee: +threshold[min] }));
        this.profiles = profiles.map(item => item.processor);
        this.paypalEnv = this.http.mode === 'test' ? 'sandbox' : 'production';

        if (this.profiles.includes('stripe')) {
            const stripe = profiles.find(item => item.processor === 'stripe');

            this.stripeKey = stripe.key;
            this.secure = stripe['3d_secure'];

            this.stripe = Stripe(this.stripeKey);
            this.elements = this.stripe.elements();
        }

        if (this.profiles.includes('paypal')) {
            const paypal = profiles.find(item => item.processor === 'paypal');

            this.paypalKey = paypal.key;
            setTimeout(()=>{
                loadScript({
                 "clientId":this.paypalKey,
                "components":"messages,buttons",
                "currency":this.currency,
                "intent":"authorize"})
                    .then((paypal)=>{
                        this.paypalLoaded.next(true);
                        this.paypal = paypal
                    })
                    .catch((err)=>{console.log('error'+JSON.stringify(err));this.paypalLoaded.next(false);})
                },50);
        }
    }

    private loadScript(url: string) {
        if (document.querySelector(`script[src="${url}"]`)) return;

        const script = document.createElement('script') as any;

        script.type = 'text/javascript';
        script.src = url;

        document.getElementsByTagName('head')[0].appendChild(script);
    }
}
