<div class="wcs-w-full rezbot-session-bookit" id="rezbot-session-bookit">
    <div class="wcs-w-full wcs-bg-white wcs-rounded">
        <div class="wcs-w-full wcs-py-10 wcs-flex wcs-justify-center wcs-items-center" *ngIf="loading">
            <div class="wcs-w-32 wcs-h-32 wcs-border-blue-500 wcs-border-t-2 wcs-border-r-2 wcs-rounded-full wcs-spin"></div>
        </div>
        <app-sessions-table
            *ngIf="step === 0"
            [loading]="loading"
            [date]="date"
            [venueId]="venueId"
            [packageId]="packageId"
            [sessionId]="sessionId"
            [showSpaces]="showSpaces"
            [booking]="booking"
            [noavail]="noavail"
            [selectable]="selectable"
            (toggleLoading)="loading = $event"
            (dateChange)="date = $event"
            (selectItem)="setItem($event)"
            (setRules)="venue = $event"
            (setVenue)="venue = $event"
        ></app-sessions-table>
        <app-customer-data
            *ngIf="step === 1"
            [booking]="booking"
            [date]="date"
            [package]="package"
            [session]="session"
            [formData]="form"
            [priceOptions]="priceOptions"
            [dirs]="dirs"
            [loading]="bookingLoading"
            [terms]="terms"
            [venue]="venue"
            [promo]="promo"
            (back)="back($event)"
            (createBooking)="createBooking($event)"
            (apply)="apply($event)"
        ></app-customer-data>
        <app-payments
            *ngIf="step === 2"
            [date]="date"
            [package]="package"
            [session]="session"
            [formData]="form"
            [priceOptions]="priceOptions"
            [booking]="booking"
            [dirs]="dirs"
            [promo]="promo"
            [voucher]="voucher"
            [duration]="timerDuration"
            [message]="message"
            [venue]="venue"
            (back)="step = 1"
            (next)="step = 3"
            (reset)="back()"
            (redirect)="redirect()"
            (apply)="apply($event)"
        ></app-payments>
        <app-booking-fields
            *ngIf="step === 3"
            [booking]="booking"
            [package]="package"
            [session]="session"
            [date]="date"
            [venue]="venue"
            [priceOptions]="priceOptions"
            (redirect)="redirect()"
        ></app-booking-fields>
    </div>
</div>