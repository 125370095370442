import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';

import { Package, Availability } from 'src/app/models/venues.model';
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-booking-fields',
    templateUrl: './booking-fields.component.html'
})
export class BookingFieldsComponent implements OnInit {
    @Input() booking: any;
    @Input() venue: Package;
    @Input() package: Package;
    @Input() session: Availability;
    @Input() date: Date;
    @Input() priceOptions: any[];

    @Output() redirect: EventEmitter<any> = new EventEmitter();

    public loading = false;
    public bookingFields: any[] = [];

    constructor(
        private http: HttpService
    ) { }

    ngOnInit() {
        this.setBookingFields();
    }

    public get hint(): any[] {
        let empty: any;

        const group = this.bookingFields.find(item => {
            empty = item.fields.find(val => val.value === '');

            return !!empty;
        });

        if (!group || !empty) return ['fill_all', {}];

        return ['fill_in_for', { name: empty.name, title: group.title }];
    }

    public saveExtraFields(): void {
        if (this.loading) return;
        this.loading = true;

	const headers = { 'X-API-KEY': this.booking.guest_key };

        this.http.post('/bookings', {
            booking_id: this.booking.booking_id,
            booking_fields: this.bookingFields[0],
            participants: this.bookingFields.slice(1).map(item => ({ booking_fields: item }))
        },{headers}).subscribe(() => {
            this.redirect.emit();
            // this.bookingService.redirectToConfirmation(this.booking, this.brand);
        }, err => {
            console.log(err);
            this.loading = false;
        });
    }

    private setBookingFields(): void {
        const people: string[] = this.priceOptions.reduce((acc, item) => ([
            ...acc, ...Array(item.qty_requested).fill(item.name)
        ]), []);

        const fields = this.package.booking_fields.map(item => {
            const inputName = item.name.replace(/\s/g, '_').toLowerCase();

            if (item.type === 'list') return { ...item, value: null, inputName };
            if (item.type === 'bool') return { ...item, value: false, inputName };

            return { ...item, value: '', type: item.type || 'text', inputName };
        }).filter(item => {
            if (item.type === 'list' && (!item.opt || !item.opt.length)) return false;

            return true;
        });

        const bookingFields = fields.filter(item => item.b);
        const participantFields = fields.filter(item => item.p);
        const finalFields = [{ title: '', group: '', fields: bookingFields }];
        const peopleNum = this.priceOptions.reduce((acc, item) => acc + item.qty_requested, 0);

        let lastField = people[0];

        for (let index = 0, counter = 1; index < peopleNum; index++, counter++) {
            if (lastField !== people[index]) counter = 1;
            lastField = people[index];

            finalFields.push({
                title: `${people[index]} ${counter}`,
                group: people[index].toLowerCase().replace(/ /g, '_'),
                fields: cloneDeep(participantFields)
            });
        }

        this.bookingFields = finalFields;
    }
}
