<div class="wcs-w-full wcs-p-4 wcs-flex wcs-items-center wcs-flex-col sm:wcs-flex-row wcs-justify-center wcs-text-white wcs-text-lg wcs-font-semibold wcs-bg-accent wcs-rounded-t">
    <p class="sm:wcs-mr-5">{{ venue.name }}</p>
    <p class="sm:wcs-mr-5">{{ date | date:'d MMMM yyyy' }}</p>
    <p class="sm:wcs-mr-5">{{ package.name }}</p>
    <p>{{ session.session_name }}</p>
</div>
<div class="wcs-w-full wcs-p-4 wcs-border wcs-border-accent wcs-border-t-0 wcs-rounded-b">
    <div class="wcs-w-full wcs-text-center wcs-mb-4 wcs-italic wcs-font-light wcs-text-accent">
        <p>
            {{ 'booking_fields.title' | translate }}<br />
            {{ 'booking_fields.sub' | translate }}
        </p>
    </div>
    <form class="wcs-w-full" #custmFields="ngForm" (ngSubmit)="saveExtraFields()">
        <ng-container *ngFor="let group of bookingFields; index as fieldsIndex; last as lastGroup">
            <ng-container *ngIf="group.fields.length">
                <h1 class="wcs-text-md wcs-text-accent wcs-font-semibold wcs-text-gray-700" *ngIf="fieldsIndex !== 0">
                    {{ group.title }}</h1>
                <div class="wcs-w-full wcs-flex md:wcs-flex-row wcs-flex-col md:wcs-flex-wrap"
                    [class.wcs-mb-4]="!lastGroup">
                    <div *ngFor="let field of group.fields; index as fieldIndex" class="wcs-w-full md:wcs-w-1/2"
                        [ngClass]="fieldIndex % 2 === 0 ? 'md:pr-1' : 'md:pl-1'">
                        <ng-container [ngSwitch]="field.type">
                            <ng-container *ngSwitchCase="'bool'"> <label class="wcs-mb-2 wcs-text-accent">
                                    {{ field.name }} <input type="checkbox" [(ngModel)]="field.value"
                                        [name]="field.inputName + '_' + fieldsIndex + '_' + fieldIndex" /> </label>
                            </ng-container>
                            <ng-container *ngSwitchCase="'list'">
                                <ng-select class="wcs-w-full wcs-custom wcs-low-alpha wcs-mb-2" [items]="field.opt"
                                    [placeholder]="field.name"
                                    [name]="field.inputName + '_' + fieldsIndex + '_' + fieldIndex"
                                    [(ngModel)]="field.value" required></ng-select>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'"> <input (focus)="dt1.open()"
                                    class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full wcs-mb-2"
                                    [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [placeholder]="field.name"
                                    [name]="field.inputName + '_' + fieldsIndex + '_' + fieldIndex"
                                    [(ngModel)]="field.value" readonly required>
                                <owl-date-time pickerType="calendar" [firstDayOfWeek]="1" #dt1></owl-date-time>
                            </ng-container>
                            <ng-container *ngSwitchDefault> <input
                                    class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full wcs-mb-2"
                                    [(ngModel)]="field.value" [placeholder]="field.name"
                                    [name]="field.inputName + '_' + fieldsIndex + '_' + fieldIndex" [type]="field.type"
                                    required /> </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <div class="wcs-w-full wcs-text-right"> <button
                class="wcs-py-2 wcs-rounded wcs-border-accent md:wcs-w-auto wcs-w-full wcs-border hover:wcs-bg-aaccent wcs-px-6"
                [disabled]="custmFields.invalid" type="submit"> <span class="wcs-text" *ngIf="!loading">Save</span> <span
                    class="wcs-hint" *ngIf="!loading">{{ hint[0] | translate:hint[1] }}</span>
                <div class="wcs-spinner-loader wcs-spinner-inverse" *ngIf="loading">
                    <div class="wcs-bounce1"></div>
                    <div class="wcs-bounce2"></div>
                    <div class="wcs-bounce3"></div>
                </div>
            </button> </div>
    </form>
</div>