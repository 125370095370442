import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

import { Availability, Package } from 'src/app/models/venues.model';
import { HttpService } from 'src/app/services/http.service';
import { PaymentService } from 'src/app/services/payment.service';
import { combineLatest } from 'rxjs';

function getParameterByName(name: string) {
    const url = (window.location !== window.parent.location) ? document.referrer : document.location.href;

    name = name.replace(/[\[\]]/g, '\\$&');

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results || !results[2]) return null;

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

//declare var fbq: any;

@Component({
    selector: 'app-geronigo-bookit',
    templateUrl: './geronigo-bookit.component.html'
})
export class GeronigoBookitComponent implements OnInit, OnChanges {
    @Output() mounted: EventEmitter<void> = new EventEmitter();

    @Input() rosterBaseUrl = 'https://roster.rezbot.com/';
    @Input() duration = '0';
    @Input() message = 'This availability will be held on your behalf for';
    @Input() startDate: string;
    @Input() venueId: string;
    @Input() packageId: string;
    @Input() sessionId: string;
    @Input() showSpaces = false;
    @Input() fbad: string;
    @Input() gclid: string;
    @Input() bingad: string;
    @Input() affiliateId: string;
    @Input() adId: string;
    @Input() brandId: string;
    @Input() selectable: string;
    @Input() noavail = 'FULL';

    public loading = true;
    public bookingLoading = '';
    public date: Date = null;
    public booking: any;
    public package: Package;
    public session: Availability;
    public priceOptions: any[] = [];
    public form: any = {};
    public step = 0;
    public dirs: any = {};
    public terms: any[] = [];
    public promo: any;
    public voucher: any;
    public venue: any;

    constructor(
        private http: HttpService,
        private payment: PaymentService,
        private sanitizer: DomSanitizer,
        private translate: TranslateService
    ) {
        this.translate.setDefaultLang('en');

        // tslint:disable-next-line: no-string-literal
        window['dataLayer'] = window['dataLayer'] || [];

        if (this.apiUrl && this.appKey && this.mode) this.getDirs();
    }

    ngOnInit() {
        this.mounted.emit();

        this.duration = getParameterByName('duration') || this.duration;
        this.message = getParameterByName('message') || this.message;
        this.startDate = getParameterByName('start-date') || this.startDate;
        this.venueId = getParameterByName('venue-id') || this.venueId;
        this.packageId = getParameterByName('package-id') || this.packageId;
        this.sessionId = getParameterByName('session-id') || this.sessionId;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.startDate && changes.startDate.currentValue) this.date = moment(changes.startDate.currentValue).toDate();
        if (changes.lang && changes.lang.currentValue) this.translate.use(changes.lang.currentValue);

        if (changes.apiUrl || changes.appKey || changes.apiKey) this.getDirs();
    }

    @Input()
    set mode(mode: 'live'|'test') { this.http.mode = mode; }
    get mode(): 'live'|'test' { return this.http.mode as any; }

    @Input()
    set apiUrl(url: string) { this.http.baseURL = url; }
    get apiUrl(): string { return this.http.baseURL; }

    @Input()
    set appKey(key: string) { this.http.appKey = key; }
    get appKey(): string { return this.http.appKey; }

    @Input()
    set apiKey(key: string) { this.http.apiKey = key; }
    get apiKey(): string { return this.http.apiKey; }

    @Input()
    set originApp(param: string) { this.http.params.origin_app = param; }
    get originApp(): string { return this.http.params.origin_app; }

    @Input()
    set originCta(param: string) { this.http.params.origin_cta = param; }
    get originCta(): string { return this.http.params.origin_cta; }

    @Input()
    set lang(param: string) { this.http.lang = param; }
    get lang(): string { return this.http.lang; }

    public get timerDuration(): number {
        return +this.duration;
    }

    public setItem({ package: pack, session, price_options }: { package: Package, session: Availability, price_options: any[] }): void {
        this.step = 1;

        if (
            !this.package ||
            !this.session ||
            this.package.package_id !== pack.package_id ||
            this.session.session_id !== session.session_id
        ) this.priceOptions = price_options;

        this.package = pack;
        this.session = session;

        if (!this.payment.initialized) this.payment.init(this.package.pay_profile, this.dirs.pay_profile);
    }

    public back(form?: any): void {
        if (form) this.form = form;

        this.step = 0;
    }

    public apply({ type, item }: { type: any, item: string }): void {
        this[type] = item;
    }

    public createBooking({ form, priceOptions, enquiry }: { form: any, priceOptions: any[], enquiry: boolean }): void {
        if (this.bookingLoading) return;
        this.bookingLoading = enquiry ? 'enquiry' : 'booking';

        if (priceOptions) this.priceOptions = priceOptions;
        if (form) this.form = form;

        const data: any = {
            _sub: 'all',
            cb: 'enquiry_submitted_track_v2',
            method: 'post',
            resource: 'bookings',
            action: 'create',
            force_comms: 1,
            customer_name: [this.form.first_name, this.form.last_name].filter(Boolean).join(' '),
            customer_email: this.form.customer_email,
            subscribed: form.subscribed,
            customer_tel: this.form.customer_tel.replace(/ |-/g, ''),
            title: this.form.title,
            group_type: this.form.group_type || null,
            message: this.form.message || null,
            newsletter: this.form.newsletter ? '1' : '0',
            customer_latitude: null,
            customer_longitude: null,
            geronigo_widget: '1',
            theme_code: 'geronigo',
            currency: this.package.currency,
            origin_url: window.location.origin,
            referrer: document.referrer,
            _target: 'bookings',
            booking_terms: 1,
            transactions: [],
            fbad: this.fbad || '',
            gclid: this.gclid || '',
            bindad: this.bingad || '',
            affiliate_id: this.affiliateId || '',
            brand_id: this.brandId || '',
            _tracker: {
                fbad: this.fbad || false,
                gclid: this.gclid || false,
                bindad: this.bingad || false,
                affiliate_id: this.affiliateId || false,
            },
            components: [
                {
                    qty_requested: this.priceOptions.reduce((acc, item) => acc + item.qty_requested, 0),
                    venue_id: this.package.venue_id,
                    activity_id: this.package.activity_id,
                    package_id: this.package.package_id,
                    with_package_id: this.package.package_id,
                    session_id: this.session.session_id,
                    availability_id: this.session.availability_id,
                    package_name: this.package.name,
                    session_name: this.session.session_name,
                    package_price: this.package.price,
                    start_date: moment(this.date).format('YYYY-MM-DD'),
                    price_options: this.priceOptions,
                    held_for: +this.duration ? +this.duration : null
                }
            ]
        };

        if (enquiry) {
            data.status = 'enquiry';
            data.originally = 'enquiry';
        } else {
            data.status = 'cart';
            data.originally = 'cart';
        }

        if (this.booking && data.status === 'cart') {
            data.booking_id = this.booking.booking_id;
            data.components[0].component_id = this.booking.components[0].component_id;
        }

        const headers: any = {};

        if (this.booking) headers['X-API-KEY'] = this.booking.guest_key;

        this.http.post('/bookings', data, { headers }).pipe(
            finalize(() => this.bookingLoading = '')
        ).subscribe(res => {
            this.booking = res.body.bookings[0];

            if (data.status === 'cart') localStorage.setItem('wcs-booking', JSON.stringify(this.booking));
            else localStorage.removeItem('wcs-booking');

            // tslint:disable-next-line: no-string-literal
            window['dataLayer'].push({
                event: enquiry ? 'conversion_enquiry' : 'conversion_cart_booking',
                conversion_type: enquiry ? 'enquiry' : 'cart_booking',
                order_id: this.booking.booking_id,
                value: this.booking.total_cost,
                label: enquiry ? 'enquiry' : 'booking',
                currency: this.booking.currency,
                category: 'conversion',
                product_ids: `v${this.package.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`,
                action: enquiry ? 'submitted' : 'cart'
            });

            /*if (typeof fbq !== 'undefined') {
                const fbqData = {
                    value: this.booking.total_cost,
                    currency: this.booking.currency,
                    content_category: 'booking',
                    content_type: 'product',
                    contents: [
                        {
                          id: `v${this.package.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`,
                          quantity: this.booking.qty_requested
                        }
                    ],
                    product_ids: `v${this.package.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`
                };

                if (enquiry) fbq('track', 'Lead', fbqData);
                else fbq('track', 'AddToCart', fbqData);
            }
            */
            if (!enquiry) this.step = 2;
            else this.redirect();
        });
    }

    public redirect(): void {
        window.location.href = `https://rezbot.com/${this.booking.guest_key}`;
    }

    private getDirs(): void {
        combineLatest([
            this.http.get('directories', {
                params: { directories: 'texts,group_types,pay_profile' }
            }),
            this.http.get('companies', {
                params: { company_id: 'self' }
            })
        ]).subscribe(([res, companyRes]) => {
            const booking = JSON.parse(localStorage.getItem('wcs-booking'));
            const company = companyRes.body.companies[0];
            const dirs = res.body;
            const threshold = { ...dirs.pay_profile.booking_fee.threshold };

            dirs.fees = Object.keys(threshold).map(min => ({ min: +min, fee: +threshold[min] }));
            dirs.group_types = dirs.group_types.map(item => Object.values(item)[0]);

            this.terms = dirs.texts.terms_booking.map(item => ({
                title: this.sanitizer.bypassSecurityTrustHtml((item.title || '').replace(/\n/g, '<br />')),
                text: this.sanitizer.bypassSecurityTrustHtml((item.text || '').replace(/\n/g, '<br />'))
            }));

            if (booking && booking.company_id === company.company_id) this.booking = booking;

            this.dirs = dirs;
        });
    }
}
