<div class="wcs-w-full wcs-p-4 wcs-flex wcs-items-center wcs-flex-col sm:wcs-flex-row wcs-justify-center wcs-text-white wcs-text-lg wcs-font-semibold wcs-bg-accent wcs-rounded-t">
    <p class="wcs-mr-5">{{ venue.name }}</p>
    <p class="wcs-mr-5">{{ date | date:'EEEE, d MMMM yyyy' }}</p>
    <p class="wcs-mr-5">{{ package.name }}</p>
    <p>{{ session.session_name }}</p>
    <div class="wcs-ml-5 wcs-p-1 wcs-font-medium wcs-rounded wcs-text-white wcs-bg-green-500 wcs-text-xs" *ngIf="promo">
        -{{ promo.promo.promo_fixed ? (promo.promo.promo_fixed | currency:booking.currency) : (promo.promo.promo_percent + '%') }}: {{ promo.promo.name }}
    </div>
    <div class="wcs-ml-5 wcs-p-1 wcs-font-medium wcs-rounded wcs-text-white wcs-bg-green-500 wcs-text-xs" *ngIf="session.promo_id">
        -{{ session.promo_fixed ? (session.promo_fixed | currency:session.currency) : (session.promo_percent + '%') }} will be applied at checkout
    </div>
</div>
<div class="wcs-w-full wcs-p-4 wcs-border wcs-border-accent wcs-border-t-0 wcs-rounded-b">
    <form [formGroup]="form" (ngSubmit)="next()">
        <div class="wcs-w-full wcs-flex wcs-items-start wcs-flex-wrap">
            <div class="md:wcs-w-1/2 wcs-w-full wcs-mb-1/2 wcs-mb-3 wcs-text-accent"
                *ngFor="let option of priceOptions; index as i" [class.md:wcs-pl-2]="i % 2 !== 0"
                [class.md:wcs-pr-2]="i % 2 === 0">
                <app-price-option
                    [options]="priceOptions"
                    [error]="error"
                    [index]="i"
                    [option]="option"
                    [currency]="package.currency"
                    [package]="package"
                    [session]="session"
                    [people]="people"
                    [minPax]="minPax"
                    (update)="calculateTotal($event)"
                ></app-price-option>
            </div>
        </div>
        <div class="wcs-w-full wcs-flex md:wcs-flex-row wcs-flex-col wcs-items-center wcs-mb-2">
            <div class="md:wcs-w-1/3 wcs-w-full md:wcs-pr-1 wcs-mb-2 md:wcs-mb-0">
                <input formControlName="first_name"
                    type="text"
                    class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full"
                    [placeholder]="('First Name' | translate) + '*'" />
            </div>
            <div class="md:wcs-w-1/3 wcs-w-full md:wcs-px-1 wcs-mb-2 md:wcs-mb-0">
                <input formControlName="last_name"
                    type="text"
                    class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full"
                    [placeholder]="'Last Name' | translate" />
            </div>
            <div class="md:wcs-w-1/3 wcs-w-full md:wcs-pl-1"> <input formControlName="customer_email" type="email"
                    class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full"
                    [placeholder]="('customer_data.email' | translate) + '*'" /> </div>
        </div>
        <div class="wcs-w-full wcs-flex md:wcs-flex-row wcs-flex-col wcs-items-center wcs-mb-2">
            <div class="md:wcs-w-1/2 wcs-w-full md:wcs-pr-1 wcs-mb-2 md:wcs-mb-0"> <input formControlName="customer_tel"
                    type="tel"
                    class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full"
                    [placeholder]="('customer_data.phone' | translate) + '*'" /> </div>
            <div class="md:wcs-w-1/2 wcs-w-full md:wcs-pl-1">
                <ng-select formControlName="group_type" [searchable]="false" [items]="dirs.group_types"
                    class="wcs-w-full wcs-custom wcs-custom-height wcs-low-alpha" [placeholder]="'customer_data.group_type' | translate"></ng-select>
            </div>
        </div>
        <div class="wcs-w-full wcs-mb-2">
            <textarea
                formControlName="message"
                class="wcs-rounded wcs-bg-transparent wcs-border wcs-border-aaccent focus:wcs-border-accent wcs-p-4 wcs-text-accent wcs-w-full"
                rows="5"
                [placeholder]="'customer_data.message' | translate"
            ></textarea>
        </div>
        <div class="wcs-w-full wcs-mb-3">
            <label class="wcs-text-accent wcs-flex wcs-items-center wcs-p-0 wcs-border-0 wcs-mb-2">
                <input type="checkbox" formControlName="subscribed" class="wcs-flex-shrink-0 wcs-w-6 wcs-h-6 md:wcs-w-auto md:wcs-h-auto" />
                <span class="wcs-ml-3 md:wcs-text-base wcs-text-sm">
                    Subscribe to our newsletter
                </span>
            </label>
            <label class="wcs-text-accent wcs-flex wcs-items-center wcs-p-0 wcs-border-0">
                <input type="checkbox" formControlName="terms" class="wcs-flex-shrink-0 wcs-w-6 wcs-h-6 md:wcs-w-auto md:wcs-h-auto" />
                <span class="wcs-ml-3 md:wcs-text-base wcs-text-sm">
                    {{ 'terms.text' | translate }} <a href="javascript:void(0)" (click)="termsEl.hidden = !termsEl.hidden" class="wcs-underline wcs-text-accent">{{ 'terms.link' | translate }}</a>
                </span>
            </label>
            <div class="wcs-w-full wcs-mt-4 wcs-text-accent wcs-border wcs-border-aaccent wcs-p-3 wcs-overflow-scroll" style="height: 400px" hidden #termsEl>
                <div *ngFor="let item of terms; last as isLast" [class.wcs-mb-0]="isLast" [class.wcs-mb-3]="!isLast">
                    <h5 [innerHtml]="item.title" class="wcs-m-0 wcs-mb-2 wcs-text-base wcs-font-semibold"></h5>
                    <p [innerHtml]="item.text" class="wcs-m-0 wcs-text-sm"></p>
                </div>
            </div>
        </div>
        <div class="wcs-mb-3 wcs-rounded wcs-p-3 wcs-flex wcs-items-start wcs-justify-end wcs-text-right wcs-text-lg wcs-text-accent"
            style="background: rgba(74, 85, 104, 0.3)">
            <div class="wcs-mr-5">
                <p class="wcs-text-base">{{ 'totals.cost' | translate }}:</p>
                <p class="wcs-text-base" *ngIf="fee > 0.00">{{ 'totals.fee' | translate }}:</p>
                <p class="wcs-text-base wcs-font-semibold">{{ 'totals.due' | translate }}:</p>
            </div>
            <div>
                <p class="wcs-text-base">{{ total | currency:package.currency:'symbol-narrow' }}</p>
                <p class="wcs-text-base" *ngIf="fee > 0.00">{{ fee | currency:package.currency:'symbol-narrow' }}</p>
                <p class="wcs-text-base wcs-font-semibold">{{ (total + fee) | currency:package.currency:'symbol-narrow' }}</p>
            </div>
        </div>
        <p *ngIf="showMessage" class="wcs-m-0 wcs-text-red-500 wcs-text-right wcs-text-sm wcs--mt-2 wcs-mb-2">{{ 'hints.required_fields' | translate }}</p>
        <div
            class="wcs-w-full wcs-flex wcs-items-center sm:wcs-flex-row wcs-flex-col-reverse wcs-justify-between wcs-text-accent">
            <a href="javascript:void(0)" class="wcs-font-normal wcs-text-sm" (click)="goBack()">
                <fa-icon [icon]="faChevronLeft"></fa-icon> {{ 'buttons.back' | translate }}
            </a>
            <div class="sm:wcs-w-auto wcs-w-full">
                <button
                    *ngIf="venue?.online_enquiries === '1'"
                    (click)="next(true)"
                    type="button"
                    [disabled]="form.invalid || loading || people <= 0"
                    class="wcs-py-2 wcs-w-full sm:wcs-w-auto wcs-px-4 wcs-mb-2 sm:wcs-mb-0 wcs-rounded wcs-border-accent wcs-border hover:wcs-bg-aaccent"
                >
                    <span *ngIf="loading !== 'enquiry'" class="wcs-text">{{ 'buttons.enquire' | translate }}</span>
		    <span class="wcs-hint" *ngIf="!loading">{{ hint | translate }}</span>
		    <div class="wcs-spinner-loader" *ngIf="loading === 'enquiry'">
                        <div class="wcs-bounce1"></div>
                        <div class="wcs-bounce2"></div>
                        <div class="wcs-bounce3"></div>
                    </div>
                </button>
                <button
                    *ngIf="acceptPayments"
                    type="submit"
                    [disabled]="form.invalid || loading || people <= 0"
                    class="wcs-py-2 wcs-px-4 wcs-w-full wcs-mb-2 md:wcs-ml-2 sm:wcs-mb-0 sm:wcs-w-auto wcs-rounded wcs-border-accent wcs-border hover:wcs-bg-aaccent"
                >
                    <span *ngIf="loading !== 'booking'" class="wcs-text">{{ 'buttons.book_now' | translate }}</span>
		    <span class="wcs-hint" *ngIf="!loading">{{ hint | translate }}</span>
                    <div class="wcs-spinner-loader" *ngIf="loading === 'booking'">
                        <div class="wcs-bounce1"></div>
                        <div class="wcs-bounce2"></div>
                        <div class="wcs-bounce3"></div>
                    </div>
                </button> </div>
        </div>
    </form>
</div>
