import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CalendarUtils, CalendarMonthViewDay } from 'angular-calendar';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { intersection } from 'lodash';
import * as moment from 'moment';
import { tap, finalize } from 'rxjs/operators';
import { faChevronRight, faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';

import { HttpService } from 'src/app/services/http.service';
import { Availability } from 'src/app/models/venues.model';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit, OnChanges {
    @Input() venueId: string;
    @Input() selectable: string;
    @Input() packageIds: string[];

    @Output() dateChange: EventEmitter<Date> = new EventEmitter();
    @Output() closeCalendar: EventEmitter<void> = new EventEmitter();

    public faChevronRight = faChevronRight;
    public faChevronLeft = faChevronLeft;
    public faClose = faTimes;

    public viewDate: Date = new Date();
    public loading = true;
    public refresh = new Subject();

    private availabilities: Availability[] = [];
    private availabilitiesSub: Subscription;

    constructor(
        private calendarUtils: CalendarUtils,
        private http: HttpService
    ) { }

    ngOnInit() {
        this.getAvailabilities(this.viewDate);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.packageIds) this.getAvailabilities(this.viewDate);
    }

    public beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        body.forEach(day => {
            if (day.isPast) return;

            const date = moment(day.date).format('YYYY-MM-DD');

            const sessions = this.availabilities.filter(item => {
                return item.start_date === date && item.avail_status !== 'closed';

            }).map(item => item.avail_status);

            if (!sessions.length) return day.cssClass = 'closed';

            if (sessions.includes('limited')) day.cssClass = 'limited';
            else if (sessions.includes('available')) day.cssClass = 'available';
            else day.cssClass = 'closed';

        });
    }

    public changeViewDate(direction: 'prev' | 'next', setRender = false): void {
        let date: moment.Moment;

        if (direction === 'prev') {
            date = moment(this.viewDate).subtract(1, 'month');

            if (date.isBefore(moment().startOf('month'))) return;
        } else if (direction === 'next') {
            date = moment(this.viewDate).add(1, 'month');
        }

        this.viewDate = date.toDate();

        this.getAvailabilities(date.toDate());
    }

    public dayClicked({ day }: { day: CalendarMonthViewDay }): void {
        if (day.cssClass === 'closed' || day.isPast) return;

        this.dateChange.emit(day.date);
    }

    private getAvailabilities(date: Date) {
        if (this.availabilitiesSub) this.availabilitiesSub.unsubscribe();

        this.loading = true;

        const view = this.calendarUtils.getMonthView({ viewDate: date, weekStartsOn: 1 });

        this.availabilitiesSub = this.http.get('/availabilities', {
            params: {
                start_date: moment(view.period.start).format('YYYY-MM-DD'),
                end_date: moment(view.period.end).format('YYYY-MM-DD'),
                venue_id: this.venueId,
                package_id: this.packageIds.length>0? this.packageIds.join(',') : this.selectable,
                'src':'calendar'
            }
        }).pipe(
            tap(res => {
                const selected = this.packageIds.map(Number);
                this.availabilities = res.body.availabilities.filter(item => {
                    if (!selected.length) return true;
                    if(selected.map(sitem=>item.package_id == sitem).length) return true;

                    if(item.package_ids)
                        return intersection(selected, item.package_ids).length;
                    return false;
                });
            }),
            finalize(() => this.loading = false)
        ).subscribe(() => this.refresh.next(true));
    }
}
